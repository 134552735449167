import { Modal } from '../lib/components/govuk-frontend/Modal';
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { TextError } from "../lib/components/govuk-frontend/TextError";
import { Input } from "../lib/components/govuk-frontend/Input";
import { useRef, useState } from "react";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import Spinner from "../lib/components/govuk-frontend/Snipper";
import { Button } from "../lib/components/govuk-frontend/Button";
import { Details } from "../lib/components/govuk-frontend/Details";
import { Link } from "react-router-dom";
import { LeadForm } from "./LeadForm";
import { RadioButtons } from "../lib/components/govuk-frontend/RadioButtons";
import { Label } from "../lib/components/govuk-frontend/Label";
import TextInset from "../lib/components/govuk-frontend/TextInset";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import { companiesHouseApi, companiesHouseSearchApi } from "../lib/helpers/companiesHouseApi";
import { useAuthorizationHeader } from "../lib/helpers/auth";

ExistingCompanyFind.hasStarted = data => {
    return data?.existingCompany;
}

ExistingCompanyFind.isValid = data => {
    return data?.existingCompany;
}

export function existingCompanySummary(existingCompany) {
    return (
        <>
            <strong>{existingCompany?.title}</strong>
            <Hint>
                {existingCompany?.description}
                <br />
                Registered Address is “{existingCompany?.address_snippet}”
            </Hint>
        </>
    );
}

export function ExistingCompanyFind({ data, setData, onComplete }) {
    const txtRef = useRef();
    const [showErrors, setShowErrors] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [companiesHouseServiceIsUnavailable, setCompaniesHouseServiceIsUnavailable] = useState(false);
    const [hideSearch, setHideSearch] = useState(false);
    const [chosenIndex, setChosenIndex] = useState(-1);
    const authorizationHeader = useAuthorizationHeader();

    const [showModal, setShowModal] = useState(false);

    const [name, setName] = useState("");
    const [result, setResult] = useState(null);

    const validate = () => {
        if (ExistingCompanyFind.isValid(data)) {
            return true;
        }
        setShowErrors(new Date().getTime());
        return false;
    }

    const items_per_page = 5;
    const queryCompaniesFromCompaniesHouseService = async companyName => {

        setIsLoading(true);
        setCompaniesHouseServiceIsUnavailable(false);

        try {
            const response = await companiesHouseSearchApi(authorizationHeader, {
                q: companyName,
                items_per_page,
                restrictions: "active-companies"
            })
            if (response.status !== 200) {
                throw response;
            }
            const json = await response.json();
            if (json.setting.success != 1) {
                throw new Error('cant get data');
            }
            const data = json.data;
            setResult(data);

        } catch (error) {
            setCompaniesHouseServiceIsUnavailable(true);
            console.error(error);
        }
        setIsLoading(false);
    }

    const search = () => {
        setHideSearch(true);
        setResult(null);
        queryCompaniesFromCompaniesHouseService(name);
    }

    const onChange = x => {
        setChosenIndex(-1);
        setHideSearch(false);
        setResult(null);
        setName(x);
        setData({
            existingCompany: null
        });
    }

    if (ExistingCompanyFind.isValid(data)) {
        return (
            <>
                <Heading>Find Your Company</Heading>
                <Label>Your Company</Label>
                <TextInset>
                    {existingCompanySummary(data.existingCompany)}
                </TextInset>

                <div className="govuk-button-group" style={{ justifyContent: "space-between" }}>
                    <ContinueButton data={data} validate={validate} onContinue={onComplete} />
                    <Button warning onClick={() => setShowModal(true)}>
                        Choose a different company
                    </Button>

                    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                        <div className="custom-modal-header">
                            <h2 className="custom-modal-title">Confirmation</h2>
                            <button className="custom-modal-close" aria-label="Close modal" onClick={() => setShowModal(false)}>
                                &times;
                            </button>
                        </div>
                        <div className="custom-modal-content">
                            <p>Would you like to change the chosen company</p>
                        </div>
                        <div className="custom-modal-footer">
                            <button className="govuk-button govuk-button--warning" onClick={()  => {
                                    setData({
                                        existingCompany: null,

                                        // VAT number
                                        isExistingCompanyVatRegistered_No: null,
                                        isExistingCompanyVatRegistered_Yes: null,
                                        existingCompanyVatNumber: null,
                                        existingCompanyVatCertificateDocument: null,

                                        // AUTH code
                                        existingCompanyAuthCode: null,
                                    });
                                    setShowModal(false);
                                }
                            }>
                                OK
                            </button>
                            <button className="govuk-button" onClick={() => setShowModal(false)}>
                                Cancel
                            </button>
                        </div>
                    </Modal>
                </div>
            </>
        );
    }

    return (
        <>
            <Heading>Find Your Company</Heading>
            <Input
                reff={txtRef}
                label="Your company name"
                hint="We'll search through Companies House services to find your company"
                value={name}
                onChange={onChange}
                onSubmit={() => search()}
                readOnly={isLoading}
            />
            {!!isLoading && (
                <Spinner hint="We are now searching through Companies House services to find your company" />
            )}

            {!!result?.items?.length && (
                <>
                    <RadioButtons
                        label="Please choose your company"
                        hint="If your company is not listed below please use a more specific search keyword"
                        options={result.items.map(existingCompanySummary)}
                        checkedIndex={chosenIndex}
                        onSelect={(_, i) => setChosenIndex(i)}
                        requiredMessage="Please choose your company"
                        showErrors={showErrors}
                    />
                    <Details
                        summary="My company is not listed above"
                        body={
                            result.total_results <= items_per_page
                                ? "These are all the results returned from Companies House services. Please check your search keyword and try again."
                                : `There are a total of ${result.total_results} companies matching your query, but we're only showing ${items_per_page} of them. Please use a more speficic search keyword to find your company.`
                        }
                    />
                    <Button disabled={chosenIndex === -1} onClick={() => setData({ existingCompany: result.items[chosenIndex] })}>
                        Choose
                    </Button>
                </>
            )}

            {result?.items?.length === 0 && (
                <TextError>
                    Couldn't find any companies with that name
                    <p />
                    <Button secondary onClick={() => { setName(""); txtRef.current.focus(); }}>Try another name</Button>
                </TextError>
            )}

            {!data?.existingCompany && !hideSearch && (
                <>
                    <Details
                        summary="Helpful Info"
                        body={(
                            <>
                                Please type a part of your company name or company number.
                                <p />
                                If you don't have a company and you want to setup one right now, click <Link to={'/' + LeadForm.path}>Setup A New Company</Link>
                            </>
                        )}
                    />
                    <Button onClick={search} disabled={!name}>Search</Button>
                </>
            )}
            {!!companiesHouseServiceIsUnavailable && (
                <TextError>
                    Company query service is currently not available.
                    <p />
                    This service is provided by Companies House and probably it is a temporary error.
                    <p />
                    Please try again in a moment.
                    <p />
                    <Button secondary onClick={search}>
                        Try again
                    </Button>
                </TextError>
            )}
        </>
    );
}
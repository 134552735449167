export default function Link1({ onClick, warning, style, ...props }) {
    return (
        <a
            href=""
            className="govuk-link"
            onClick={e => { e.preventDefault(); onClick?.(); }}
            style={{ ...style, color: warning ? "#d4351c" : "unset" }}
            {...props}
        >
            {props.children}
        </a>
    );
}
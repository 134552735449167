import { Link } from "react-router-dom";
//import logo from "../../../assets/images/SadlerandFrost_Logo.png";
import logo from "../../../assets/images/logo.svg";
import { LeadForm } from "../../../pages/LeadForm";

export default function Header({ data, title }) {
    return (
        <header className="govuk-header" role="banner" data-module="govuk-header">
            <div className="govuk-header__container govuk-width-container">
                <div className="govuk-header__logo">
                    <Link to={!LeadForm.isValid(data) ? LeadForm.path : "/"} className="govuk-header__link govuk-header__link--homepage">
                        <span className="govuk-header__logotype">
                            <img src={logo} height={25} />
                        </span>
                    </Link>
                </div>
                <div className="govuk-header__content">
                    <Link to={LeadForm.path} className="govuk-header__link govuk-header__link--service-name">
                        {title}
                    </Link>
                </div>
            </div>
        </header>
    );
}
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Label } from './Label';

export default function Select({ label, options, onSelect }) {
    const id = useMemo(() => uuid(), []);
    return (
        <div className="govuk-form-group">
            <Label htmlFor={id}>{label}</Label>
            <select className="govuk-select" id={id} style={{ width: "100%" }} onChange={onSelect}>
                <option value=''>Select your address</option>
                {options.map((x, i) => (
                    <option key={i} value={x.value || i}>{x.label || x}</option>
                ))}
            </select>
        </div>
    );
}
import { isAddressValid } from "../../lib/components/govuk-frontend/Address";
import { getBirthDateValidationMessage } from "../../lib/components/govuk-frontend/DateInput";

export function officerName(data, i) {
    i = parseInt(i) || 0;
    const officer = data.officers[i];

    if (officer.isCorporate) {
        return officer.corporateName || `Corporate #${i + 1}`
    }

    return getPersonName(officer) || `Officer #${i + 1}`;
}

export function getTotalNumberOfShares(officers) {
    return (officers || [])
        .filter(x => x.isShareholder)
        .map(x => x.numberOfShares)
        .filter(x => !!x && !isNaN(x))
        .map(x => parseInt(x))
        .reduce((a, b) => a + b, 0);
}

export function getSharePercentage(officer, totalNumberOfShares) {
    return parseFloat((parseInt(officer?.numberOfShares) / totalNumberOfShares * 100).toFixed(2)) + "%";
}

export function getPersonName(officer) {
    return [
        officer.title,
        officer.firstname,
        officer.lastname
    ].filter(a => a).join(' ');
}

export function doesNameContainInitials(name) {
    return !!` ${name} `.replace(/\./g, ' ').match(/ . /);
}

export const isEmailValid = (email) => {
    return !!(String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
};

export const minAge = 16;
export const maxAge = 100;
export const getFirstInvalidFieldOfOfficer = (officer, tabIndex) => {
    // Type
    if (tabIndex == null || tabIndex >= 0) {
        const missingField = ["corporateName", "registrationNumber"].filter(x => !officer[x])[0];

        if (!officer.isIndividual && missingField) {
            return { tabIndex: 0, missingField };
        }
    }

    // Role
    if (tabIndex == null || tabIndex >= 1) {
        if ((!officer.isPSC && !officer.isDirector && !officer.isShareholder)
            || (officer.isShareholder && !parseInt(officer.numberOfShares))) {
            return { tabIndex: 1, missingField: "numberOfShares" };
        }
    }

    // Person
    if (tabIndex == null || tabIndex >= 2) {

        const missingField = [
            "title", "firstname", "lastname",
            { label: "dateOfBirth", isValid: !getBirthDateValidationMessage(officer.dateOfBirth, minAge, maxAge) },
            "nationality", "proofOfIdDocument"
        ].filter(x => typeof (x) === "object" ? !x.isValid : !officer[x])[0];

        if (missingField) {
            return { tabIndex: 2, missingField: missingField.label || missingField };
        }

        const invalidField = [
            "firstname", "lastname"
        ].filter(x => doesNameContainInitials(officer[x]))[0];

        if (invalidField) {
            return { tabIndex: 2, missingField: invalidField };
        }
    }

    // Auth
    if (tabIndex == null || tabIndex >= 3) {
        const missingField = ["authMothersMaidenName", "authFathersFirstName", "authTownOfBirth"].filter(x => (officer[x]?.length || 0) < 3)[0];

        if (missingField) {
            return { tabIndex: 3, missingField };
        }
    }

    // Address
    if (tabIndex == null || tabIndex >= 4) {
        if (!isAddressValid(officer.address, true)) {
            return { tabIndex: 4 };
        }
        if (!officer.proofOfAddressDocument) {
            return { tabIndex: 4, missingField: "proof-of-address" };
        }
        if (!officer.proofOfAddressDocument2) {
            return { tabIndex: 4, missingField: "proof-of-address2" };
        }
    }

    return null;
}

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../lib/components/govuk-frontend/Button";
import { ContinueButton } from "../../lib/components/govuk-frontend/ContinueButton";
import { Details } from "../../lib/components/govuk-frontend/Details";
import { Heading } from "../../lib/components/govuk-frontend/Heading";
import { Hint } from "../../lib/components/govuk-frontend/Hint";
import { StatusTag, TaskStatus } from "../../lib/components/govuk-frontend/TaskList";
import { TextError } from "../../lib/components/govuk-frontend/TextError";
import { useWindowDimensions } from "../../lib/helpers/window-dimensions";
import { getFirstInvalidFieldOfOfficer, getSharePercentage, getTotalNumberOfShares, officerName } from "./officer-helper-functions";

const tableMaxWidth = 650;

NewCompanyOfficers.hasStarted = data => {
    return data?.officers?.length;
}

NewCompanyOfficers.isValid = data => {
    return Array.isArray(data?.officers)
        && data?.officers.filter(x => x.isPSC).length
        && data?.officers.filter(x => x.isDirector).length
        && data?.officers.filter(x => x.isShareholder).length
        && data?.officers.filter(x => getFirstInvalidFieldOfOfficer(x)).length === 0;
}

export default function NewCompanyOfficers({ data, setData, onComplete }) {
    const navigate = useNavigate();

    const [showErrors, setShowErrors] = useState(false);
    const total = data.officers?.length || 0;
    const hasPSC = !!data.officers?.filter(x => x.isPSC).length;
    const hasDirector = !!data.officers?.filter(x => x.isDirector).length;
    const hasShareholder = !!data.officers?.filter(x => x.isShareholder).length;

    const { isSmallWindow } = useWindowDimensions();

    useEffect(() => {
        !total && add();
    }, []);

    const firstFlag = useRef(true);
    useEffect(() => {
        if (firstFlag.current) {
            firstFlag.current = false;
            return;
        }
        if (total && Object.keys(data.officers[total - 1]).length === 0) {
            navigate(`/officer/${total - 1}/0`);
        }
    }, [data]);

    const add = () => {
        setData(oldData => {
            return { officers: [...(oldData.officers || []), {}] };
        });
    }

    const validate = () => {
        for (let i = 0; i < total; i++) {
            const invalidField = getFirstInvalidFieldOfOfficer(data.officers[i]);
            if (invalidField) {
                navigate(`/officer/${i}/${invalidField.tabIndex}?showErrors&singleFix`);
                return false;
            }
        }

        if (!hasPSC || !hasDirector || !hasShareholder) {
            setShowErrors(true);
            return false;
        }

        return true;
    }

    const totalNumberOfShares = getTotalNumberOfShares(data.officers);

    return (
        <>
            <Heading>Officers</Heading>
            {!!total && (
                <Hint>
                    You added <b>{total}</b> {total > 1 ? "officers" : "officer"}.
                    <br />
                    You can add as many as needed.
                    <table cellPadding={8} cellSpacing={0} style={{ marginTop: 6, marginBottom: 16, borderCollapse: "collapse", borderColor: "lightgray", width: "100%", maxWidth: tableMaxWidth }} border={2}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: isSmallWindow ? "center" : "right" }}>Full Name</th>
                                <th>PSC</th>
                                <th>Dir</th>
                                <th>Share</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(data.officers || []).map((x, i) => (
                                <tr key={i} className="highlightOnHover" onClick={() => navigate(`/officer/${i}/${showErrors ? "1?singleFix" : "0"}`)}>
                                    <td style={{ textAlign: isSmallWindow ? "left" : "center" }}>
                                        <div style={{ float: isSmallWindow ? "none" : "right" }}>
                                            {officerName(data, i)}
                                        </div>
                                        <StatusTag floatLeft status={getFirstInvalidFieldOfOfficer(x) ? TaskStatus.INCOMPLETE : TaskStatus.COMPLETED} />
                                    </td>
                                    <td style={{
                                        textAlign: "center",
                                        borderColor: showErrors && !hasPSC ? "red" : "unset",
                                        borderWidth: showErrors && !hasPSC ? 3 : 1
                                    }}>
                                        {!!x.isPSC && "✔"}
                                    </td>
                                    <td style={{
                                        textAlign: "center",
                                        borderColor: showErrors && !hasDirector ? "red" : "unset",
                                        borderWidth: showErrors && !hasDirector ? 3 : 1
                                    }}>
                                        {!!x.isDirector && "✔"}
                                    </td>
                                    <td style={{
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                        borderColor: showErrors && !hasShareholder ? "red" : "unset",
                                        borderWidth: showErrors && !hasShareholder ? 3 : 1
                                    }}>
                                        {!!x.isShareholder && !!x.numberOfShares && !isNaN(x.numberOfShares) && (
                                            <>
                                                {x.numberOfShares} of {totalNumberOfShares}
                                                <br />
                                                {getSharePercentage(x, totalNumberOfShares)}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <i>*Tap on the row to edit or remove.</i>
                </Hint>
            )}
            <center style={{ maxWidth: tableMaxWidth }}>
                <Button secondary onClick={add}>Add officer</Button>
            </center>
            <Details
                summary="Helpful Info"
                body={
                    <>
                        <p>Please add, if needed, any further officers / shareholders / PSCs.</p>
                        <ul className="govuk-list govuk-list--bullet govuk-list--spaced">
                            <li>You must have at least one director who is an individual of at least 16 years of age.</li>
                            <li>You must have at least one shareholder.</li>
                            <li>Both the director and shareholder roles can be fulfilled by the same individual.</li>
                            <li>You must include details of all People with Significant Control over the company (PSCs), who may also be officers or shareholders.</li>
                        </ul>
                    </>
                }
            />
            {showErrors && !hasPSC && <TextError>You must have at least one PSC (person with significant control).</TextError>}
            {showErrors && !hasDirector && <TextError>You must have at least one director who is an individual (not corporate) of at least 16 years of age.</TextError>}
            {showErrors && !hasShareholder && <TextError>You must have at least one shareholder.</TextError>}
            {!!total && (
                <ContinueButton data={data} validate={validate} onContinue={onComplete} />
            )}
        </>
    );
}


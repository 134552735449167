export default function Tabs({ headers, contents, activeIndex, setActiveIndex }) {

    return (
        <div className="js-enabled govuk-tabs" data-module="govuk-tabs">
            <ul className="govuk-tabs__list">
                {(headers || []).map((x, i) => (
                    <li key={i} className={"govuk-tabs__list-item" + (i === activeIndex ? " govuk-tabs__list-item--selected" : "")}>
                        <a className="govuk-tabs__tab" href="" onClick={e => { e.preventDefault(); setActiveIndex(i); }}>
                            {x}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="govuk-tabs__panel">
                {contents?.[activeIndex]}
            </div>
        </div>
    );
}
import { Input } from "../../lib/components/govuk-frontend/Input";

export default function OfficerAuth({ officer, setOfficer, showErrors }) {
    return (
        <>
            <Input
                id="auth-1"
                label="Mother's maiden name"
                hint="Please enter first 3 letters"
                maxLength={3}
                value={officer.authMothersMaidenName}
                onChange={x => setOfficer({ authMothersMaidenName: x.toUpperCase().replace(/[^a-zA-Z]/g, '') })}
                requiredMessage="Please enter at least 3 letters of mother's maiden name"
                isInvalid={(officer.authMothersMaidenName?.length || 0) < 3}
                showErrors={showErrors}
            />
            <Input
                id="auth-2"
                label="Father's first name"
                hint="Please enter first 3 letters"
                maxLength={3}
                value={officer.authFathersFirstName}
                onChange={x => setOfficer({ authFathersFirstName: x.toUpperCase().replace(/[^a-zA-Z]/g, '') })}
                requiredMessage="Please enter at least 3 letters of father's first name"
                isInvalid={(officer.authFathersFirstName?.length || 0) < 3}
                showErrors={showErrors}
            />
            <Input
                id="auth-3"
                label="Town of birth"
                hint="Please enter first 3 letters"
                maxLength={3}
                value={officer.authTownOfBirth}
                onChange={x => setOfficer({ authTownOfBirth: x.toUpperCase().replace(/[^a-zA-Z]/g, '') })}
                requiredMessage="Please enter at least 3 letters of town of birth"
                isInvalid={(officer.authTownOfBirth?.length || 0) < 3}
                showErrors={showErrors}
            />
        </>
    );
}
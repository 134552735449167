import { Hint } from "./Hint";
import loadingIcon from "../../../assets/images/loading_icon.gif";

export default function Spinner({ hint }) {
    return (
        <center style={{ padding: 16 }}>
            <img src={loadingIcon} width="64" style={{ marginBottom: 16 }} />
            <Hint>{hint}</Hint>
        </center>
    );
}
export function TextError(props) {
    return (
        <div className="govuk-warning-text">
            <span className="govuk-warning-text__icon" aria-hidden="true" style={{ backgroundColor: "#d4351c", borderColor: "#d4351c", color: "white" }}>
                !
            </span>
            <strong className="govuk-warning-text__text" style={{ color: "#d4351c" }}>
                {" "}
                <span className="govuk-warning-text__assistive">Warning</span>{" "}
                {props.children}{" "}
            </strong>
        </div>
    );
}

export async function companiesHouseApi(authorizationHeader, url, params) {
    params = !params ? "" : ("?" + new URLSearchParams(params).toString());
    const input = `${process.env.REACT_APP_API_URL}api/CompaniesHouseApi?url=` + encodeURIComponent(url + params);
    const init = {
        headers: {
            'Authorization': authorizationHeader,
            'Content-Type': 'application/json'
        }
    }

    return fetch(input, init);
}

export async function companiesHouseSearchApi(authorizationHeader, params) {
    const input = `${process.env.REACT_APP_API_URL}api/onboarding/company/search_company`;
    const init = {
        headers: {
            'Authorization': authorizationHeader,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(params)
    }

    return fetch(input, init);
}
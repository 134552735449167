import { v4 as uuid } from 'uuid';
import { useMemo } from "react";
import { Label } from './Label';
import { Hint } from './Hint';

export function RadioButtons({ options, checkedIndex, onSelect, smallLabel, label, hint, requiredMessage, showErrors }) {
    const key = useMemo(() => uuid(), []);
    const id = i => `radio_${key}_${i}`;

    const isInvalid = !!(showErrors && requiredMessage && checkedIndex == null);

    return (
        <div className={"govuk-form-group" + (isInvalid ? " govuk-form-group--error" : "")}>
            {!!label && (
                <Label smallLabel={smallLabel}>
                    {label}
                </Label>
            )}
            {!!hint && (
                <Hint>
                    {hint}
                </Hint>
            )}
            {isInvalid && (
                <p className="govuk-error-message">
                    <span className="govuk-visually-hidden">Error:</span> {requiredMessage}
                </p>
            )}
            <div className="govuk-radios">
                {options.map((x, i) => (
                    <div key={i} className="govuk-radios__item">
                        <input
                            className="govuk-radios__input"
                            id={id(i)}
                            type="radio"
                            checked={checkedIndex == null ? false : (checkedIndex === i)}
                            onChange={() => onSelect(x, i)}
                        />
                        <label className="govuk-label govuk-radios__label" htmlFor={id(i)}>
                            {x}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

import { useEffect, useRef, useState } from "react";
import "../../../assets/styles/autocomplete.css"
import { useWindowDimensions } from "../../helpers/window-dimensions";
import { Input } from "./Input";

const optionRefs = new Map();

const scrollParentToChild = (parent, child) => {
    if (!parent || !child) return;

    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    if (childRect.top < parentRect.top) {
        parent.scrollTop -= parentRect.top - childRect.top;
        return;
    }

    if (childRect.bottom > parentRect.bottom) {
        parent.scrollTop += childRect.bottom - parentRect.bottom;
        return;
    }
}

export default function Autocomplete({ id, name, autoComplete, smallLabel, label, presetValue, hint, filterFunction, onSelect, onContinue, format, clearOnSelect, requiredMessage, isInvalid, showErrors, placeholder, showAll }) {

    const inputRef = useRef();
    const optionsParentRef = useRef();
    const [value, setValue] = useState(presetValue || "");
    const [matches, setMatches] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selected, setSelected] = useState(presetValue);
    const { isSmallWindow } = useWindowDimensions();

    useEffect(() => {
        const m = (value?.length && value !== selected) ? (filterFunction?.(value) || []) : [];
        if (m.length === 1 && value.toLowerCase() == getLabel(m[0]).toLowerCase()) {
            // auto select on perfect match
            setSelected(getLabel(m[0]));
            onSelect(m[0]);
            setValue(getLabel(m[0]));
            setMatches([]);
            return;
        }
        setMatches(m);
        setActiveIndex(0);
    }, [value, selected]);

    useEffect(() => {
        scrollParentToChild(
            optionsParentRef.current,
            optionRefs.get(activeIndex)
        );
    }, [activeIndex]);

    const onKeyDown = x => {
        const direction =
            x === "ArrowUp" ? -1
                : x === "ArrowDown" ? 1
                    : 0;
        if (direction) {
            setActiveIndex(i => (i + direction + matches.length) % matches.length);
        }

        if (x === "Enter") {
            onSubmit();
        }

        if (x === "Escape") {
            setMatches([]);
        }
    }

    const onFocus = x => {
        const m = (filterFunction?.(value) || []);
        setMatches(m);
    }

    const select = x => {
        const selectedValue = typeof (format) === "function" ? format(matches[activeIndex]) : matches[activeIndex];
        setValue(clearOnSelect ? "" : selectedValue);
        setSelected(selectedValue);
        onSelect?.(matches[activeIndex]);
        !clearOnSelect && setTimeout(inputRef.current.blur, 1);
    }

    const onSubmit = () => {
        if (activeIndex < matches.length) {
            select(matches[activeIndex]);
        } else {
            onContinue?.();
        }
    }

    const getOptionClassName = i => {
        const className =
            i === activeIndex ? "autocomplete__option--focused"
                : i % 2 ? "autocomplete__option--odd"
                    : "";
        return "autocomplete__option " + className;
    }

    const getLabel = x => {
        return typeof (format) === "function" ? format(x) : x;
    }

    return (
        <div className="autocomplete__wrapper">
            <Input
                reff={inputRef}
                id={id}
                name={name}
                autoComplete={autoComplete}
                smallLabel={smallLabel}
                label={label}
                hint={hint}
                placeholder={placeholder || "Start typing to see the options..."}
                value={value}
                onChange={x => { setValue(x); setSelected(null); onSelect?.(null); }}
                onBlur={() => { setValue(clearOnSelect ? "" : selected); !selected && onSelect?.(null) }}
                onKeyDown={onKeyDown}
                onFocus={showAll && onFocus}
                requiredMessage={requiredMessage}
                isInvalid={isInvalid}
                showErrors={showErrors}
            />
            {!!matches.length && (
                <ul
                    ref={optionsParentRef}
                    className="autocomplete__menu autocomplete__menu--inline autocomplete__menu"
                    style={{ marginTop: isSmallWindow ? -20 : -30, marginBottom: 30 }}
                >
                    {matches.map((x, i) => (
                        <li
                            ref={e => optionRefs.set(i, e)}
                            key={i}
                            className={getOptionClassName(i)}
                            onClick={() => select?.(x)}
                            onMouseDown={() => select?.(x)}
                            onMouseMove={() => setActiveIndex(i)}
                        >
                            {getLabel(x)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
export function Button({ children, onClick, disabled, warning, secondary, ...props }) {
    return (
        <button
            data-prevent-double-click="true"
            disabled={disabled}
            className={
                "govuk-button"
                + (warning ? " govuk-button--warning" : "")
                + (secondary ? " govuk-button--secondary" : "")
            }
            data-module="govuk-button"
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
}

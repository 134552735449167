import { toast } from "sonner";
import Address from "../../lib/components/govuk-frontend/Address";
import FileUpload from "../../lib/components/govuk-frontend/FileUpload";
import { Hint } from "../../lib/components/govuk-frontend/Hint";
import { Label } from "../../lib/components/govuk-frontend/Label";

export default function OfficerAddress({ officer, officerName, setOfficer, showErrors }) {
    const handleFileUpload = (data) => {
        setOfficer(data);
        let same = false;
        if (data.proofOfAddressDocument) {
            if (officer.proofOfAddressDocument2 && officer?.proofOfAddressDocument2.OriginalFilename == data?.proofOfAddressDocument?.OriginalFilename) {
                same = true
            }
        } else if (data.proofOfAddressDocument2) {
            if (officer.proofOfAddressDocument && officer?.proofOfAddressDocument.OriginalFilename == data?.proofOfAddressDocument2?.OriginalFilename) {
                same = true
            }
        }

        if (same) {
            toast.error('You have select same document');
        }

    }

    return (
        <>
            <Label>Residential Address for {officerName}</Label>
            <Hint></Hint>
            <Address
                withCountry
                value={officer.address}
                onChange={address => setOfficer({ address })}
                showErrors={showErrors}
                withCounty={false}
            />
            <FileUpload
                id="proof-of-address"
                label={"Proof of address"}
                hint={"Please upload bank statement or utility bill"}
                requiredMessage="Please upload proof of address"
                isInvalid={!officer.proofOfAddressDocument}
                showErrors={showErrors}
                value={officer.proofOfAddressDocument}
                // onChange={proofOfAddressDocument => setOfficer({ proofOfAddressDocument })}
                onChange={(proofOfAddressDocument) => handleFileUpload({ proofOfAddressDocument })}
            />
            <FileUpload
                id="proof-of-address2"
                label={""}
                hint={"Upload another bank statement or utility bill"}
                requiredMessage="Please upload proof of address"
                isInvalid={!officer.proofOfAddressDocument2}
                showErrors={showErrors}
                value={officer.proofOfAddressDocument2}
                // onChange={proofOfAddressDocument2 => setOfficer({ proofOfAddressDocument2 })}
                onChange={(proofOfAddressDocument2) => handleFileUpload({ proofOfAddressDocument2: proofOfAddressDocument2 })}
            />
        </>
    );
}
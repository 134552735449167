import searchFromNationalities from "../../assets/json/nationalities";
import Autocomplete from "../../lib/components/govuk-frontend/Autocomplete";
import { DateInput, getBirthDateValidationMessage } from "../../lib/components/govuk-frontend/DateInput";
import FileUpload from "../../lib/components/govuk-frontend/FileUpload";
import { Hint } from "../../lib/components/govuk-frontend/Hint";
import { Input } from "../../lib/components/govuk-frontend/Input";
import { doesNameContainInitials, getFirstInvalidFieldOfOfficer, maxAge, minAge, isEmailValid } from "./officer-helper-functions";

export default function OfficerPersonDetails({ officer, setOfficer, showErrors }) {
    return (
        <>
            {!!officer.isCorporate && (
                <Hint>Enter below the details for the individual person authorised to act for this corporate person.</Hint>
            )}
            <Autocomplete
                id="title"
                label="Prefix"
                name="Title"
                autoComplete="honorific-prefix"
                filterFunction={() => ["Mr", "Mrs", "Miss", "Ms"]}
                format={x => x}
                presetValue={officer.title}
                onSelect={title => setOfficer({ title })}
                isInvalid={!officer.title}
                showErrors={showErrors}
                requiredMessage="Please enter the honorific prefix"
                hint="Mr, Mrs, Miss, Ms etc."
                showAll={true}
            />
            {/* <Input upperCase
                id="title"
                label="Prefix"
                name="Title"
                hint="Mr, Mrs, Miss, Ms etc."
                autoComplete="honorific-prefix"
                value={officer.title}
                onChange={title => setOfficer({ title })}
                list={["Mr", "Mrs", "Miss", "Ms"]}
                isInvalid={!officer.title}
                showErrors={showErrors}
                requiredMessage="Please enter the honorific prefix"
            /> */}
            <Input upperCase
                label="First Name"
                name="FirstName"
                autoComplete="given-name"
                value={officer.firstname}
                onChange={firstname => setOfficer({ firstname })}
                isInvalid={(!officer.firstname || doesNameContainInitials(officer.firstname))}
                showErrors={showErrors}
                requiredMessage={doesNameContainInitials(officer.firstname) ? "Please replace any initials with the full name" : "Please enter the first name"}
            />
            <Input upperCase
                label="Last Name"
                name="LastName"
                autoComplete="family-name"
                value={officer.lastname}
                onChange={lastname => setOfficer({ lastname })}
                isInvalid={(!officer.lastname || doesNameContainInitials(officer.lastname))}
                showErrors={showErrors}
                requiredMessage={doesNameContainInitials(officer.lastname) ? "Please replace any initials with the full name" : "Please enter the last name"}
            />
            {officer.isCorporate &&
                <Input
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={officer.email}
                    onChange={email => setOfficer({ email })}
                    isInvalid={(!officer.email || !isEmailValid(officer.email))}
                    showErrors={showErrors}
                    requiredMessage="Please enter valid email address"
                />}
            <form autoComplete="true">
                <DateInput
                    id="date-of-birth"
                    label="Date of birth"
                    value={officer.dateOfBirth}
                    setValue={x => setOfficer({ dateOfBirth: { ...officer.dateOfBirth, ...x } })}
                    isInvalid={getBirthDateValidationMessage(officer.dateOfBirth, minAge, maxAge)}
                    showErrors={showErrors}
                    requiredMessage={getBirthDateValidationMessage(officer.dateOfBirth, minAge, maxAge)}
                />
            </form>
            <Autocomplete
                id="nationality"
                label="Nationality"
                autoComplete="off"
                filterFunction={searchFromNationalities}
                format={x => x.label}
                presetValue={officer.nationality?.label}
                onSelect={nationality => setOfficer({ nationality })}
                isInvalid={!officer.nationality}
                showErrors={showErrors}
                requiredMessage="Please choose the nationality"
            />
            <FileUpload
                id="proof-of-id"
                label={"Proof of ID"}
                hint="Please upload passport or UK driving licence"
                requiredMessage="Please upload proof of ID"
                isInvalid={!officer.proofOfIdDocument}
                showErrors={showErrors}
                value={officer.proofOfIdDocument}
                onChange={proofOfIdDocument => setOfficer({ proofOfIdDocument })}
            />
        </>
    );
}
import { useEffec, useState } from "react";
import searchFromCountries from "../../../assets/json/countries";
import Autocomplete from "./Autocomplete";
import { Input } from "./Input";
import Select from "./Select";
import { Label } from "./Label";
import { useAuthorizationHeader } from "../../helpers/auth";

export function isAddressValid(address, withCountry) {
    return !!address?.AddressHouseNumber
        && !!address?.AddressLine1
        && !!address?.TownOrCity
        && !!address?.Postcode
        && (!withCountry || !!address?.Country);
}

export function addressSummary(address) {
    const fields = [
        address?.AddressHouseNumber,
        address?.AddressLine1,
        address?.AddressLine2,
        address?.TownOrCity,
        address?.County,
        address?.Postcode,
        address?.Country?.label,
    ].filter(x => x);

    return (
        <>
            {fields.map((x, xi) => <div key={xi}>{x}</div>)}
        </>
    );
}

const tmpSuggestion = [
    {
        address: "Westminster Gallery, Westminster Central Hall, Westminster, London",
        label: "Westminster Gallery, Westminster Central Hall, Westminster, London",
        url: "/get/NDg5YmQ5NzY5Zjk0YmI5IDUxMTQ3MTI1",
        id: "NDg5YmQ5NzY5Zjk0YmI5IDUxMTQ3MTI1",
        value: "NDg5YmQ5NzY5Zjk0YmI5IDUxMTQ3MTI1"
    },
    {
        "address": "Westminster School Lawrence Hall, Greycoat Street, Westminster, London",
        "label": "Westminster School Lawrence Hall, Greycoat Street, Westminster, London",
        "url": "/get/MDZlNWYxYTUyMDA0NDUyIDUxMDY1ODM0",
        "id": "MDZlNWYxYTUyMDA0NDUyIDUxMDY1ODM0",
        "value": "MDZlNWYxYTUyMDA0NDUyIDUxMDY1ODM0",
    },
    {
        "address": "Knight Frank Victoria & Westminster, 51 Victoria Street, Westminster, London",
        "label": "Knight Frank Victoria & Westminster, 51 Victoria Street, Westminster, London",
        "url": "/get/ZDNhNGJhMmY5YjU0ZWRlIDUxMTQ2ODU2",
        "value": "ZDNhNGJhMmY5YjU0ZWRlIDUxMTQ2ODU2",
        "id": "ZDNhNGJhMmY5YjU0ZWRlIDUxMTQ2ODU2",
    },
    {
        "address": "Easy R M S, 8 Westminster City Hall, Westminster, London",
        "label": "Easy R M S, 8 Westminster City Hall, Westminster, London",
        "url": "/get/NWRmYWUxMWY2N2E0NGQyIDUxMDM5NDcx",
        "id": "NWRmYWUxMWY2N2E0NGQyIDUxMDM5NDcx",
        "value": "NWRmYWUxMWY2N2E0NGQyIDUxMDM5NDcx",
    },
    {
        "address": "Howick Place Westminster, 7 Howick Place, Westminster, London",
        "label": "Howick Place Westminster, 7 Howick Place, Westminster, London",
        "url": "/get/ZGQyYTFjMGI0YTkzZjRjIDUxMTE5NDg3",
        "id": "ZGQyYTFjMGI0YTkzZjRjIDUxMTE5NDg3",
        "value": "ZGQyYTFjMGI0YTkzZjRjIDUxMTE5NDg3",
    }
]

export default function Address({ withCountry, title, value, onChange, showErrors, withCounty }) {

    const [locationInfo, setLocationInfo] = useState(null);
    const authorizationHeader = useAuthorizationHeader();

    const searchPostcode = async (postcode) => {
        // try {
        //     const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
        //     const data = await response.json();
        //     setLocationInfo(data.result);
        // } catch (error) {
        //     setLocationInfo(null);
        // }
        try {
            const dataJSON = JSON.stringify({ postcode });
            const url = `${process.env.REACT_APP_API_URL}api/onboarding/address/suggest_address`;
            const init = {
                headers: { 'Authorization': authorizationHeader, 'Content-Type': 'application/json' },
                method: 'POST',
                body: dataJSON
            };
            const response = await fetch(url, init);
            if (response.status !== 200) {
                throw response;
            }
            const json = await response.json();
            if (json.setting.success != 1) {
                throw response;
            }
            const data = json.data;
            if (!data.length) throw new Error();

            let addressList = data.map((element) => {
                return {
                    label: element.address,
                    value: element.id
                }
            });
            setLocationInfo(addressList);
        } catch (err) {
            setLocationInfo(null);
        }
    };

    const fields = [
        {
            label: "House name / number",
            field: "AddressHouseNumber",
            id: "address-house-number",
            autoComplete: "address-house-number",
            requiredMessage: "Please enter the house name / number",
        },
        {
            label: "Postcode",
            field: "Postcode",
            id: "address-postcode",
            suffix: (
                <button type="submit" className="govuk-!-padding-0 govuk-button govuk-button--secondary" onClick={() => searchPostcode(value?.Postcode)}>
                    Find in UK
                </button>
            ),
            autoComplete: "postal-code",
            inputClass: "govuk-input--width-10",
            requiredMessage: "Please enter the postcode",
        },
        {
            label: "Address line 1",
            field: "AddressLine1",
            id: "address-line-1",
            autoComplete: "address-line1",
            requiredMessage: "Please enter the address",
            format: x => x.startsWith(value?.AddressHouseNumber) ? x.substring(value?.AddressHouseNumber?.length || 0).trim() : x
        },
        {
            label: "Address line 2 (optional)",
            field: "AddressLine2",
            id: "address-line-2",
            autoComplete: "address-line2",
        },
        {
            label: "Town or city",
            field: "TownOrCity",
            id: "address-town",
            autoComplete: "address-level2",
            inputClass: "govuk-!-width-two-thirds",
            requiredMessage: "Please enter the town or city",
        },
        {
            label: "County (optional)",
            field: "County",
            inputClass: "govuk-!-width-two-thirds",
            id: "address-county",
        },
    ];

    const onSelectAddress = async (e) => {
        if (!e.target.value) {
            return
        }
        const dataJSON = JSON.stringify({ addressId: e.target.value })

        const url = `${process.env.REACT_APP_API_URL}api/onboarding/address/get_address_by_id`;
        const init = {
            headers: { 'Authorization': authorizationHeader, 'Content-Type': 'application/json' },
            method: 'POST',
            body: dataJSON
        };
        const response = await fetch(url, init);
        if (response.status !== 200) {
            throw response;
        }
        const json = await response.json();
        if (json.setting.success != 1) {
            throw response;
        }
        const address = json.data;
        if (!address) return;


        const newValue = { ...value };

        if (address.building_name) newValue["AddressHouseNumber"] = address.building_name;
        if (address.postcode) newValue["Postcode"] = address.postcode;
        if (address.line_1) newValue["AddressLine1"] = address.line_1;
        if (address.line_2) newValue["AddressLine2"] = address.line_2;
        if (address.town_or_city) newValue["TownOrCity"] = address.town_or_city;
        if (address.country) newValue["County"] = address.country;
        onChange?.(newValue);
    }

    return (
        <fieldset className="govuk-fieldset">
            {!!title && (
                <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                    <Label>{title}</Label>
                </legend>
            )}
            {fields.map((x, i) => {
                if (x.field == "County" && !withCounty) {
                    return;
                }
                const content = (
                    <div key={i}>
                        <Input
                            upperCase smallLabel label={x.label}
                            inputClass={x.inputClass}
                            id={x.id}
                            name={x.id}
                            type="text"
                            autoComplete={x.autoComplete}
                            value={value?.[x.field]}
                            onChange={v => {
                                if (x.format) {
                                    v = x.format(v)
                                }
                                const newValue = { ...value };
                                newValue[x.field] = v;
                                onChange?.(newValue);
                            }}
                            suffix={x.suffix}
                            requiredMessage={x.requiredMessage}
                            isInvalid={x.requiredMessage && !value?.[x.field]}
                            showErrors={showErrors}
                        />
                        {
                            (x.field == "Postcode" && locationInfo?.length) &&
                            <Select
                                label="Suggestion"
                                options={locationInfo}
                                onSelect={onSelectAddress}
                            />
                        }
                    </div>
                )
                return content;

            })}

            {!!withCountry && (
                <>
                    <Autocomplete
                        smallLabel label="Country"
                        id="address-country"
                        name="address-country"
                        autoComplete="country-name"
                        filterFunction={searchFromCountries}
                        format={x => x.label}
                        presetValue={value?.Country?.label}
                        onSelect={Country => onChange?.({ ...value, Country })}
                        requiredMessage="Please choose the country"
                        isInvalid={withCountry && !value?.Country?.label}
                        showErrors={showErrors}
                    />
                </>
            )}
        </fieldset>
    );
}
import { Button } from "../lib/components/govuk-frontend/Button";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { TextError } from "../lib/components/govuk-frontend/TextError";
import { Input } from "../lib/components/govuk-frontend/Input";
import { useRef, useState } from "react";
import { TextSuccess } from "../lib/components/govuk-frontend/TextSuccess";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import Spinner from "../lib/components/govuk-frontend/Snipper";
import { Details } from "../lib/components/govuk-frontend/Details";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { existingCompanySummary } from "./ExistingCompanyFind";
import { companiesHouseApi, companiesHouseSearchApi } from "../lib/helpers/companiesHouseApi";
import { useAuthorizationHeader } from "../lib/helpers/auth";

NewCompanyName.hasStarted = data => {
    return data?.companyName;
}

NewCompanyName.isValid = data => {
    return data?.companyName && data?.isCompanyNameAvailable;
}

export default function NewCompanyName({ data, setData, onComplete }) {

    const txtRef = useRef();
    const [similarNames, setSimilarNames] = useState(null);
    const [hideCheckAvailability, setHideCheckAvailability] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [companiesHouseServiceIsUnavailable, setCompaniesHouseServiceIsUnavailable] = useState(false);
    const authorizationHeader = useAuthorizationHeader();

    const items_per_page = 5;
    const queryCompanyNameFromCompaniesHouseService = async companyName => {

        setIsLoading(true);
        setCompaniesHouseServiceIsUnavailable(false);

        try {
            const response = await companiesHouseSearchApi(authorizationHeader, {
                q: companyName,
                items_per_page,
                restrictions: "active-companies legally-equivalent-company-name"
            })
            if (response.status !== 200) {
                throw response;
            }
            const json = await response.json();
            if (json.setting.success != 1) {
                throw new Error('cant get data');
            }
            const data = json.data;
            setData({ isCompanyNameAvailable: data?.items?.length == 0, isCompanyNameAvailableSkipped: false });
            setSimilarNames(data);

        } catch (error) {
            setCompaniesHouseServiceIsUnavailable(true);
            console.error(error);
        }

        setIsLoading(false);
    }

    const checkAvailability = () => {
        setHideCheckAvailability(true);
        setSimilarNames(null);

        let companyName = data.companyName;

        companyName = companyName.toUpperCase().trim();
        ["LIMITED", "LTD.", "LTD"].forEach(x => companyName = companyName.replaceAll(x, ""));
        companyName = companyName.replace(/  +/g, ' ').trim() + " LIMITED";

        if (companyName !== data.companyName) {
            setData({ companyName });
        }

        queryCompanyNameFromCompaniesHouseService(companyName);
    }

    const onChange = x => {
        setHideCheckAvailability(false);
        setSimilarNames(null);
        setData({
            companyName: x,
            isCompanyNameAvailable: false
        });
    }

    return (
        <>
            <Heading>Company Name</Heading>
            <Input upperCase
                reff={txtRef}
                label="Enter chosen company name"
                hint="We'll check the availability of proposed name right now"
                value={data.companyName}
                onChange={onChange}
                onSubmit={() => !data.isCompanyNameAvailable && checkAvailability()}
                readOnly={isLoading}
                // pattern='[^a-zA-Z!@#$&()\\-`.+,/\" ]*$'
                pattern={`[^A-Za-z!@#&,?'"$^ ]`}
            />
            {!!isLoading && (
                <Spinner hint="We're now checking the availability of your proposed company name through Companies House services..." />
            )}
            {!!data.isCompanyNameAvailable && (
                <>
                    <TextSuccess>
                        Perfect! Your chosen name is available.<br />
                    </TextSuccess>
                    <Hint>You can continue to the next step.</Hint>
                </>
            )}

            {!!similarNames?.items?.length && (
                <TextError>
                    The company name "{data.companyName}" is considered to be the 'same as' the existing names shown below:
                    <p />
                    <Button secondary onClick={() => { onChange(""); txtRef.current.focus(); }}>Try another name</Button>
                    <ul className="govuk-list">
                        {similarNames.items.map((x, i) => <li key={i}>{existingCompanySummary(x)}</li>)}
                    </ul>
                </TextError>
            )}
            {!data.isCompanyNameAvailable && !hideCheckAvailability && (
                <>
                    <Details
                        summary="Helpful Info"
                        body={(
                            <>
                                Your company name cannot be offensive.
                                <br />
                                Your name also cannot contain a ‘sensitive’ word or expression, or suggest a connection with government or local authorities, unless you get permission.
                            </>
                        )}
                    />
                    <Button onClick={checkAvailability} disabled={!data.companyName}>Check availability</Button>
                </>
            )}
            {!!companiesHouseServiceIsUnavailable && (
                <TextError>
                    Company name availability checker is currently not available.
                    <p />
                    This service is provided by Companies House and probably it is a temporary error.
                    <p />
                    Please try again in a moment.
                    <p />
                    <Button secondary onClick={checkAvailability}>
                        Try again
                    </Button>
                    <p />
                    {/* <Button secondary onClick={() => { setData({ isCompanyNameAvailable: true, isCompanyNameAvailableSkipped: true }); onComplete(); }}>
                        Skip validation and continue
                    </Button> */}
                </TextError>
            )}
            <br />
            {NewCompanyName.isValid(data) && (
                <ContinueButton data={data} onContinue={onComplete} />
            )}
        </>
    );
}

export default function searchFromCountries(str) {
    const tokens = str.toLowerCase().replace(/  +/g, ' ').trim().split(' ');
    const result = [];

    for (let i = 0; i < countries.length; i++) {
        const item = countries[i];

        let hasMatch = true;
        for (let j = 0; j < tokens.length; j++) {
            const token = tokens[j];
            if (item.labelLowerCase.indexOf(token) === -1) {
                hasMatch = false;
                break;
            }
        }
        if (hasMatch) {
            result.push(item);
        }
    }

    return result;
}

const countries = [
    { value: "826", label: "United Kingdom" },
    { value: "900", label: "England" },
    { value: "904", label: "Wales" },
    { value: "903", label: "Scotland" },
    { value: "902", label: "Northern Ireland" },
    { value: "4", label: "Afghanistan" },
    { value: "248", label: "Aland Islands" },
    { value: "8", label: "Albania" },
    { value: "910", label: "Alderney" },
    { value: "12", label: "Algeria" },
    { value: "16", label: "American Samoa" },
    { value: "20", label: "Andorra" },
    { value: "24", label: "Angola" },
    { value: "660", label: "Anguilla" },
    { value: "10", label: "Antarctica" },
    { value: "28", label: "Antigua and Barbuda" },
    { value: "32", label: "Argentina" },
    { value: "51", label: "Armenia" },
    { value: "533", label: "Aruba" },
    { value: "36", label: "Australia" },
    { value: "40", label: "Austria" },
    { value: "31", label: "Azerbaijan" },
    { value: "44", label: "Bahamas" },
    { value: "48", label: "Bahrain" },
    { value: "50", label: "Bangladesh" },
    { value: "52", label: "Barbados" },
    { value: "112", label: "Belarus" },
    { value: "56", label: "Belgium" },
    { value: "84", label: "Belize" },
    { value: "204", label: "Benin" },
    { value: "60", label: "Bermuda" },
    { value: "64", label: "Bhutan" },
    { value: "68", label: "Bolivia" },
    { value: "535", label: "Bonaire, Sint Eustatius and Saba" },
    { value: "70", label: "Bosnia and Herzegovina" },
    { value: "72", label: "Botswana" },
    { value: "74", label: "Bouvet Island" },
    { value: "76", label: "Brazil" },
    { value: "86", label: "British Indian Ocean Territory" },
    { value: "96", label: "Brunei Darussalam" },
    { value: "100", label: "Bulgaria" },
    { value: "854", label: "Burkina Faso" },
    { value: "108", label: "Burundi" },
    { value: "116", label: "Cambodia" },
    { value: "120", label: "Cameroon" },
    { value: "124", label: "Canada" },
    { value: "132", label: "Cape Verde" },
    { value: "136", label: "Cayman Islands" },
    { value: "140", label: "Central African Republic" },
    { value: "148", label: "Chad" },
    { value: "152", label: "Chile" },
    { value: "156", label: "China" },
    { value: "162", label: "Christmas Island" },
    { value: "166", label: "Cocos (Keeling) Islands" },
    { value: "170", label: "Colombia" },
    { value: "174", label: "Comoros" },
    { value: "178", label: "Congo" },
    { value: "180", label: "Congo, the Democratic Republic of the" },
    { value: "184", label: "Cook Islands" },
    { value: "188", label: "Costa Rica" },
    { value: "191", label: "Croatia" },
    { value: "192", label: "Cuba" },
    { value: "531", label: "Curacao" },
    { value: "196", label: "Cyprus" },
    { value: "203", label: "Czech Republic" },
    { value: "208", label: "Denmark" },
    { value: "262", label: "Djibouti" },
    { value: "212", label: "Dominica" },
    { value: "214", label: "Dominican Republic" },
    { value: "626", label: "East Timor" },
    { value: "218", label: "Ecuador" },
    { value: "818", label: "Egypt" },
    { value: "222", label: "El Salvador" },
    { value: "226", label: "Equatorial Guinea" },
    { value: "232", label: "Eritrea" },
    { value: "233", label: "Estonia" },
    { value: "748", label: "Eswatini" },
    { value: "231", label: "Ethiopia" },
    { value: "238", label: "Falkland Islands" },
    { value: "234", label: "Faroe Islands" },
    { value: "242", label: "Fiji" },
    { value: "246", label: "Finland" },
    { value: "250", label: "France" },
    { value: "254", label: "French Guiana" },
    { value: "258", label: "French Polynesia" },
    { value: "260", label: "French Southern Territories" },
    { value: "266", label: "Gabon" },
    { value: "270", label: "Gambia" },
    { value: "268", label: "Georgia" },
    { value: "276", label: "Germany" },
    { value: "288", label: "Ghana" },
    { value: "292", label: "Gibraltar" },
    { value: "300", label: "Greece" },
    { value: "304", label: "Greenland" },
    { value: "308", label: "Grenada" },
    { value: "312", label: "Guadeloupe" },
    { value: "316", label: "Guam" },
    { value: "320", label: "Guatemala" },
    { value: "831", label: "Guernsey" },
    { value: "324", label: "Guinea" },
    { value: "624", label: "Guinea-Bissau" },
    { value: "328", label: "Guyana" },
    { value: "332", label: "Haiti" },
    { value: "334", label: "Heard Island and McDonald Islands" },
    { value: "911", label: "Herm" },
    { value: "340", label: "Honduras" },
    { value: "344", label: "Hong Kong" },
    { value: "348", label: "Hungary" },
    { value: "352", label: "Iceland" },
    { value: "356", label: "India" },
    { value: "360", label: "Indonesia" },
    { value: "364", label: "Iran" },
    { value: "368", label: "Iraq" },
    { value: "372", label: "Ireland" },
    { value: "833", label: "Isle of Man" },
    { value: "376", label: "Israel" },
    { value: "380", label: "Italy" },
    { value: "384", label: "Ivory Coast" },
    { value: "388", label: "Jamaica" },
    { value: "392", label: "Japan" },
    { value: "832", label: "Jersey" },
    { value: "400", label: "Jordan" },
    { value: "398", label: "Kazakhstan" },
    { value: "404", label: "Kenya" },
    { value: "296", label: "Kiribati" },
    { value: "412", label: "Kosovo" },
    { value: "414", label: "Kuwait" },
    { value: "417", label: "Kyrgyzstan" },
    { value: "418", label: "Laos" },
    { value: "428", label: "Latvia" },
    { value: "422", label: "Lebanon" },
    { value: "426", label: "Lesotho" },
    { value: "430", label: "Liberia" },
    { value: "434", label: "Libya" },
    { value: "438", label: "Liechtenstein" },
    { value: "440", label: "Lithuania" },
    { value: "442", label: "Luxembourg" },
    { value: "446", label: "Macao" },
    { value: "807", label: "Macedonia" },
    { value: "450", label: "Madagascar" },
    { value: "454", label: "Malawi" },
    { value: "458", label: "Malaysia" },
    { value: "462", label: "Maldives" },
    { value: "466", label: "Mali" },
    { value: "470", label: "Malta" },
    { value: "584", label: "Marshall Islands" },
    { value: "474", label: "Martinique" },
    { value: "478", label: "Mauritania" },
    { value: "480", label: "Mauritius" },
    { value: "175", label: "Mayotte" },
    { value: "484", label: "Mexico" },
    { value: "583", label: "Micronesia" },
    { value: "498", label: "Moldova" },
    { value: "492", label: "Monaco" },
    { value: "496", label: "Mongolia" },
    { value: "499", label: "Montenegro" },
    { value: "500", label: "Montserrat" },
    { value: "504", label: "Morocco" },
    { value: "508", label: "Mozambique" },
    { value: "104", label: "Myanmar" },
    { value: "516", label: "Namibia" },
    { value: "520", label: "Nauru" },
    { value: "524", label: "Nepal" },
    { value: "528", label: "Netherlands" },
    { value: "540", label: "New Caledonia" },
    { value: "554", label: "New Zealand" },
    { value: "558", label: "Nicaragua" },
    { value: "562", label: "Niger" },
    { value: "566", label: "Nigeria" },
    { value: "570", label: "Niue" },
    { value: "574", label: "Norfolk Island" },
    { value: "408", label: "North Korea" },
    { value: "580", label: "Northern Mariana Islands" },
    { value: "578", label: "Norway" },
    { value: "512", label: "Oman" },
    { value: "586", label: "Pakistan" },
    { value: "585", label: "Palau" },
    { value: "275", label: "Palestine, State of" },
    { value: "591", label: "Panama" },
    { value: "598", label: "Papua New Guinea" },
    { value: "600", label: "Paraguay" },
    { value: "604", label: "Peru" },
    { value: "608", label: "Philippines" },
    { value: "612", label: "Pitcairn" },
    { value: "616", label: "Poland" },
    { value: "620", label: "Portugal" },
    { value: "630", label: "Puerto Rico" },
    { value: "634", label: "Qatar" },
    { value: "638", label: "Reunion" },
    { value: "642", label: "Romania" },
    { value: "643", label: "Russia" },
    { value: "646", label: "Rwanda" },
    { value: "652", label: "Saint Barthelemy" },
    { value: "654", label: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "659", label: "Saint Kitts and Nevis" },
    { value: "662", label: "Saint Lucia" },
    { value: "663", label: "Saint Martin (French part)" },
    { value: "666", label: "Saint Pierre and Miquelon" },
    { value: "670", label: "Saint Vincent and the Grenadines" },
    { value: "882", label: "Samoa" },
    { value: "674", label: "San Marino" },
    { value: "678", label: "Sao Tome and Principe" },
    { value: "912", label: "Sark" },
    { value: "682", label: "Saudi Arabia" },
    { value: "686", label: "Senegal" },
    { value: "688", label: "Serbia" },
    { value: "690", label: "Seychelles" },
    { value: "694", label: "Sierra Leone" },
    { value: "702", label: "Singapore" },
    { value: "534", label: "Sint Maarten (Dutch part)" },
    { value: "703", label: "Slovakia" },
    { value: "705", label: "Slovenia" },
    { value: "90", label: "Solomon Islands" },
    { value: "706", label: "Somalia" },
    { value: "710", label: "South Africa" },
    { value: "239", label: "South Georgia and the South Sandwich Islands" },
    { value: "410", label: "South Korea" },
    { value: "728", label: "South Sudan" },
    { value: "724", label: "Spain" },
    { value: "144", label: "Sri Lanka" },
    { value: "729", label: "Sudan" },
    { value: "740", label: "Surilabel" },
    { value: "744", label: "Svalbard and Jan Mayen" },
    { value: "752", label: "Sweden" },
    { value: "756", label: "Switzerland" },
    { value: "760", label: "Syria" },
    { value: "158", label: "Taiwan" },
    { value: "762", label: "Tajikistan" },
    { value: "834", label: "Tanzania" },
    { value: "764", label: "Thailand" },
    { value: "768", label: "Togo" },
    { value: "772", label: "Tokelau" },
    { value: "776", label: "Tonga" },
    { value: "780", label: "Trinidad and Tobago" },
    { value: "788", label: "Tunisia" },
    { value: "792", label: "Turkey" },
    { value: "795", label: "Turkmenistan" },
    { value: "796", label: "Turks and Caicos Islands" },
    { value: "798", label: "Tuvalu" },
    { value: "800", label: "Uganda" },
    { value: "804", label: "Ukraine" },
    { value: "784", label: "United Arab Emirates" },
    { value: "840", label: "United States" },
    { value: "581", label: "United States Minor Outlying Islands" },
    { value: "858", label: "Uruguay" },
    { value: "860", label: "Uzbekistan" },
    { value: "548", label: "Vanuatu" },
    { value: "336", label: "Vatican City" },
    { value: "862", label: "Venezuela" },
    { value: "704", label: "Vietnam" },
    { value: "92", label: "Virgin Islands, British" },
    { value: "850", label: "Virgin Islands, U.S." },
    { value: "876", label: "Wallis and Futuna" },
    { value: "732", label: "Western Sahara" },
    { value: "887", label: "Yemen" },
    { value: "894", label: "Zambia" },
    { value: "716", label: "Zimbabwe" },
];

countries.forEach(x => {
    x.labelLowerCase = x.label.toLowerCase();
});

import { Details } from "../lib/components/govuk-frontend/Details";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import searchFromSicCodes from "../assets/json/sic-codes";
import Autocomplete from "../lib/components/govuk-frontend/Autocomplete";
import Link1 from "../lib/components/govuk-frontend/Link1";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { Button } from "../lib/components/govuk-frontend/Button";

NewCompanyPlannedBusinessActivitiesForm.hasStarted = data => {
    return data?.plannedBusinessActivities?.length;
}

NewCompanyPlannedBusinessActivitiesForm.isValid = data => {
    return data?.plannedBusinessActivities?.length;
}


export function NewCompanyPlannedBusinessActivitiesForm({ data, setData, onComplete }) {

    const max = 4;
    const total = data.plannedBusinessActivities?.length || 0;

    const onSelect = x => {
        if (!x) {
            return;
        }

        const items = data.plannedBusinessActivities || [];
        if (items.indexOf(x) !== -1) {
            return;
        }
        setData({ plannedBusinessActivities: [...items, x] });
    }

    const remove = i => {
        if (!window.confirm("Are you sure to remove this business activity? \r\n\r\n" + data.plannedBusinessActivities[i])) {
            return;
        }
        const items = [...(data.plannedBusinessActivities || [])];
        items.splice(i, 1);
        setData({ plannedBusinessActivities: items });
    }

    const handleAddMoreClick = () => {
        const autocompleteRef = document.getElementById("companyActivitiesInpt")
        if (autocompleteRef) {
            autocompleteRef.focus();
        }
    };

    return (
        <>
            <Heading>Business Details</Heading>
            {total < max && (
                <>
                    <Autocomplete
                        id="companyActivitiesInpt"
                        label="Company's planned business activities"
                        hint="Please choose your company activities"
                        filterFunction={searchFromSicCodes}
                        onSelect={onSelect}
                        clearOnSelect
                    />
                </>
            )}

            {!!total && (
                <Hint>
                    You selected <b>{total}</b> business {total > 1 ? "activities" : "activity"}.
                    <br />
                    You can select up to {max}.
                    <table cellPadding={8} cellSpacing={0} style={{ marginTop: 6, marginBottom: 16, borderCollapse: "collapse", borderColor: "lightgray", width: "100%" }} border={2}>
                        <tbody>
                            {data.plannedBusinessActivities.map((x, i) => (
                                <tr key={i}>
                                    <td style={{ width: "99%" }}>
                                        {x}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <Link1 warning onClick={() => remove(i)}>
                                            Remove
                                        </Link1>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Hint>
            )}

            {!!NewCompanyPlannedBusinessActivitiesForm.isValid(data) && (
                <Button secondary onClick={handleAddMoreClick}>Add More</Button>
            )}

            {total < max && (
                <Details
                    summary="Helpful Info"
                    body="Type a keyword related to the business - for example, 'property', 'retail' or 'manufacture'. As you type, options will be presented - click on the one that best describes what the company does. You can have more than one code if the company has a range of activities. These are also referred to as SIC codes" />
            )}

            {!!NewCompanyPlannedBusinessActivitiesForm.isValid(data) && (
                <ContinueButton data={data} onContinue={onComplete} />
            )}
        </>
    );
}

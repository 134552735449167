import { Navigate, useNavigate } from "react-router-dom";
import { useSessionId } from "../lib/helpers/auth";
import { Button } from "../lib/components/govuk-frontend/Button";

ApplicationComplete.path = "application-complete";

export default function ApplicationComplete({ data }) {
    const sessionId = useSessionId();

    const reset = () => {
        if (window.confirm("Would you like to start a new application?")) {
            let customerID = localStorage.getItem("customerID")
            localStorage.clear();
            localStorage.setItem("customerID", customerID)
            window.location = '/';
        }
    }

    if (!data.isConfirmed) {
        return <Navigate to="/" />;
    }

    return (
        <div className="govuk-grid-column-two-thirds">
            <div className="govuk-panel govuk-panel--confirmation">
                <h1 className="govuk-panel__title">
                    Application complete
                </h1>
                <div className="govuk-panel__body">
                    Your reference number
                    <br />
                    <strong>{sessionId.split('-')[0]}</strong>
                </div>
            </div>

            <p className="govuk-body">We have sent you a confirmation email.</p>

            <h2 className="govuk-heading-m">What happens next</h2>

            <p className="govuk-body">
                Rest is on us!
            </p>
            <p className="govuk-body">
                Upon the approval of the information you provided, we'll take care of official application to Companies House Register Office immediately.
            </p>
            <p className="govuk-body">
                We will contact you either to confirm your registration, or to ask for more information.
            </p>

            {/* <p className="govuk-body">
                <a href="#" className="govuk-link">What did you think of this service?</a> (takes 30 seconds)
                <code style={{ color: "red" }}><br />TODO: Redirect to customer satisfaction form!</code>
            </p> */}

            <div style={{ textAlign: "center", marginTop: 60 }}>
                <Button secondary onClick={reset}>
                    Start a new application
                </Button>
            </div>
        </div>
    );
}
export function Details({ summary, body, inline }) {
    return (
        <details className="govuk-details" data-module="govuk-details" style={inline && { display: "inline", marginLeft: 12, marginBottom: 0 }}>
            <summary className="govuk-details__summary">
                <span className="govuk-details__summary-text">{summary}</span>
            </summary>
            <div className="govuk-details__text">
                <div className="govuk-body"> {body} </div>
            </div>
        </details>
    );
}

import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Hint } from './Hint';
import { Input } from './Input';
import { Label } from './Label';

Date.prototype.add = function (years, months, days) {
    var date = new Date(this.valueOf());
    years && date.setFullYear(date.getFullYear() + years);
    months && date.setMonth(date.getMonth() + months);
    days && date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.removeTime = function () {
    return new Date(
        new Date(this.valueOf()
        ).toLocaleDateString());
}

export function isDateValid(date) {
    if (!date || !date.year || !date.month || !date.day) {
        return false;
    }
    const dateObj = new Date(date.year, date.month - 1, date.day);
    return date.year == dateObj.getFullYear()
        && date.month - 1 == dateObj.getMonth()
        && date.day == dateObj.getDate();
}

export function dateToString(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return !isDateValid(date) ? "" : [date.day, monthNames[date.month - 1], date.year].join(' ');
}

export function getBirthDateValidationMessage(date, minAge, maxAge) {
    if (!isDateValid(date)) {
        return "Please enter a valid date";
    }

    const dateObj = new Date(date.year, date.month - 1, date.day).removeTime();
    const minDate = new Date().removeTime().add(-maxAge, 0, 0);
    const maxDate = new Date().removeTime().add(-minAge, 0, 1);

    if (dateObj < minDate) {
        return "Date of birth must be on or after " + minDate.toLocaleDateString("en-UK");
    }

    if (dateObj > maxDate) {
        return "Date of birth must be before " + maxDate.toLocaleDateString("en-UK");
    }

    return "";
}

export function DateInput({ id, smallLabel, label, hint, value, setValue, requiredMessage, isInvalid, showErrors }) {

    const inputId = useMemo(() => id || uuid(), []);
    const idYear = inputId + "-year";
    const idMonth = inputId + "-month";
    const idDay = inputId + "-day";

    const errorVisible = isInvalid && showErrors;

    return (
        <fieldset className="govuk-fieldset">
            <div className={"govuk-form-group" + (errorVisible ? " govuk-form-group--error" : "")}>
                {!!label && (
                    <Label htmlFor={idDay} smallLabel={smallLabel}>
                        {label}
                    </Label>
                )}
                {!!hint && (
                    <Hint>
                        {hint}
                    </Hint>
                )}
                {errorVisible && (
                    <p className="govuk-error-message">
                        <span className="govuk-visually-hidden">Error:</span> {requiredMessage}
                    </p>
                )}

                <div className="govuk-date-input">
                    <div className="govuk-date-input__item">
                        <div className="govuk-form-group">
                            <label className="govuk-label govuk-date-input__label" htmlFor={idDay}>
                                Day
                            </label>

                            <Input
                                inputClass="govuk-input govuk-date-input__input govuk-input--width-2"
                                id={idDay}
                                name="dobDay"
                                autoComplete="bday-day"
                                type="text"
                                pattern="[^0-9]"
                                inputMode="numeric"
                                dataAutomationId="day"
                                value={value?.day || ""}
                                onChange={x => setValue?.({ day: x.replace(/\D/g, '').replace(/^0+/, '').substring(0, 2) })}
                                isInvalid={!value?.day}
                                showErrors={showErrors}
                            />
                        </div>
                    </div>

                    <div className="govuk-date-input__item">
                        <div className="govuk-form-group">
                            <label className="govuk-label govuk-date-input__label" htmlFor={idMonth}>
                                Month
                            </label>

                            <Input
                                inputClass="govuk-input govuk-date-input__input govuk-input--width-2"
                                id={idMonth}
                                name="dobMonth"
                                autoComplete="bday-month"
                                type="text"
                                pattern="[^0-9]"
                                inputMode="numeric"
                                dataAutomationId="month"
                                value={value?.month || ""}
                                onChange={x => setValue?.({ month: x.replace(/\D/g, '').replace(/^0+/, '').substring(0, 2) })}
                                isInvalid={!value?.month}
                                showErrors={showErrors}
                            />
                        </div>
                    </div>

                    <div className="govuk-date-input__item">
                        <div className="govuk-form-group">
                            <label className="govuk-label govuk-date-input__label" htmlFor={idYear}>
                                Year
                            </label>

                            <Input
                                inputClass="govuk-input govuk-date-input__input govuk-input--width-4"
                                id={idYear}
                                name="dobYear"
                                autoComplete="bday-year"
                                type="text"
                                pattern="[^0-9]"
                                inputMode="numeric"
                                dataAutomationId="year"
                                value={value?.year || ""}
                                onChange={x => setValue?.({ year: x.replace(/\D/g, '').replace(/^0+/, '').substring(0, 4) })}
                                isInvalid={!value?.year}
                                showErrors={showErrors}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    );
}

export function TextSuccess(props) {
    return (
        <div className="govuk-warning-text">
            <span className="govuk-warning-text__icon" aria-hidden="true" style={{ backgroundColor: "#00703c", borderColor: "#00703c", fontSize: 22 }}>
                ✓
            </span>
            <strong className="govuk-warning-text__text" style={{ color: "#00703c" }}>
                {" "}
                <span className="govuk-warning-text__assistive">Warning</span>{" "}
                {props.children}{" "}
            </strong>
        </div>
    );
}

export default function searchFromNationalities(str) {
    const tokens = str.toLowerCase().replace(/  +/g, ' ').trim().split(' ');
    const result = [];

    for (let i = 0; i < nationalities.length; i++) {
        const item = nationalities[i];

        let hasMatch = true;
        for (let j = 0; j < tokens.length; j++) {
            const token = tokens[j];
            if (item.labelLowerCase.indexOf(token) === -1) {
                hasMatch = false;
                break;
            }
        }
        if (hasMatch) {
            result.push(item);
        }
    }

    return result;
}

const nationalities = [
    { value: "28", label: "British" },
    { value: "65", label: "English" },
    { value: "151", label: "Northern Irish" },
    { value: "176", label: "Scottish" },
    { value: "222", label: "Welsh" },
    { value: "1", label: "Afghan" },
    { value: "2", label: "Albanian" },
    { value: "3", label: "Algerian" },
    { value: "4", label: "American" },
    { value: "5", label: "Andorran" },
    { value: "6", label: "Angolan" },
    { value: "7", label: "Anguillan" },
    { value: "8", label: "Citizen of Antigua and Barbuda" },
    { value: "9", label: "Argentine" },
    { value: "10", label: "Armenian" },
    { value: "11", label: "Australian" },
    { value: "12", label: "Austrian" },
    { value: "13", label: "Azerbaijani" },
    { value: "14", label: "Bahamian" },
    { value: "15", label: "Bahraini" },
    { value: "16", label: "Bangladeshi" },
    { value: "17", label: "Barbadian" },
    { value: "18", label: "Botswanan" },
    { value: "19", label: "Belarusian" },
    { value: "20", label: "Belgian" },
    { value: "21", label: "Belizean" },
    { value: "22", label: "Beninese" },
    { value: "23", label: "Bermudian" },
    { value: "24", label: "Bhutanese" },
    { value: "25", label: "Bolivian" },
    { value: "26", label: "Citizen of Bosnia and Herzegovina" },
    { value: "27", label: "Brazilian" },
    { value: "29", label: "British Virgin Islander" },
    { value: "30", label: "Bruneian" },
    { value: "31", label: "Bulgarian" },
    { value: "32", label: "Burkinan" },
    { value: "33", label: "Burmese" },
    { value: "34", label: "Burundian" },
    { value: "35", label: "Cambodian" },
    { value: "36", label: "Cameroonian" },
    { value: "37", label: "Canadian" },
    { value: "38", label: "Cape Verdean" },
    { value: "39", label: "Cayman Islander" },
    { value: "40", label: "Central African" },
    { value: "41", label: "Chadian" },
    { value: "42", label: "Chilean" },
    { value: "43", label: "Chinese" },
    { value: "44", label: "Colombian" },
    { value: "45", label: "Comoran" },
    { value: "46", label: "Congolese (Congo)" },
    { value: "47", label: "Congolese (DRC)" },
    { value: "48", label: "Cook Islander" },
    { value: "49", label: "Costa Rican" },
    { value: "50", label: "Croatian" },
    { value: "51", label: "Cuban" },
    { value: "52", label: "Cymraes" },
    { value: "53", label: "Cymro" },
    { value: "54", label: "Cypriot" },
    { value: "55", label: "Czech" },
    { value: "56", label: "Danish" },
    { value: "57", label: "Djiboutian" },
    { value: "58", label: "Dominican" },
    { value: "59", label: "Citizen of the Dominican Republic" },
    { value: "60", label: "Dutch" },
    { value: "61", label: "East Timorese" },
    { value: "62", label: "Ecuadorean" },
    { value: "63", label: "Egyptian" },
    { value: "64", label: "Emirati" },
    { value: "66", label: "Equatorial Guinean" },
    { value: "67", label: "Eritrean" },
    { value: "68", label: "Estonian" },
    { value: "69", label: "Ethiopian" },
    { value: "70", label: "Faroese" },
    { value: "71", label: "Fijian" },
    { value: "72", label: "Filipino" },
    { value: "73", label: "Finnish" },
    { value: "74", label: "French" },
    { value: "75", label: "Gabonese" },
    { value: "76", label: "Gambian" },
    { value: "77", label: "Georgian" },
    { value: "78", label: "German" },
    { value: "79", label: "Ghanaian" },
    { value: "80", label: "Gibraltarian" },
    { value: "81", label: "Greek" },
    { value: "82", label: "Greenlandic" },
    { value: "83", label: "Grenadian" },
    { value: "84", label: "Guamanian" },
    { value: "85", label: "Guatemalan" },
    { value: "86", label: "Citizen of Guinea-Bissau" },
    { value: "87", label: "Guinean" },
    { value: "88", label: "Guyanese" },
    { value: "89", label: "Haitian" },
    { value: "90", label: "Honduran" },
    { value: "91", label: "Hong Konger" },
    { value: "92", label: "Hungarian" },
    { value: "93", label: "Icelandic" },
    { value: "94", label: "Indian" },
    { value: "95", label: "Indonesian" },
    { value: "96", label: "Iranian" },
    { value: "97", label: "Iraqi" },
    { value: "98", label: "Irish" },
    { value: "99", label: "Israeli" },
    { value: "100", label: "Italian" },
    { value: "101", label: "Ivorian" },
    { value: "102", label: "Jamaican" },
    { value: "103", label: "Japanese" },
    { value: "104", label: "Jordanian" },
    { value: "105", label: "Kazakh" },
    { value: "106", label: "Kenyan" },
    { value: "107", label: "Citizen of Kiribati" },
    { value: "108", label: "Kittitian" },
    { value: "109", label: "Kosovan" },
    { value: "110", label: "Kuwaiti" },
    { value: "111", label: "Kyrgyz" },
    { value: "112", label: "Lao" },
    { value: "113", label: "Latvian" },
    { value: "114", label: "Lebanese" },
    { value: "115", label: "Liberian" },
    { value: "116", label: "Libyan" },
    { value: "117", label: "Liechtenstein citizen" },
    { value: "118", label: "Lithuanian" },
    { value: "119", label: "Luxembourger" },
    { value: "120", label: "Macanese" },
    { value: "121", label: "Macedonian" },
    { value: "122", label: "Malagasy" },
    { value: "123", label: "Malawian" },
    { value: "124", label: "Malaysian" },
    { value: "125", label: "Maldivian" },
    { value: "126", label: "Malian" },
    { value: "127", label: "Maltese" },
    { value: "128", label: "Marshallese" },
    { value: "129", label: "Martiniquais" },
    { value: "130", label: "Mauritanian" },
    { value: "131", label: "Mauritian" },
    { value: "132", label: "Mexican" },
    { value: "133", label: "Micronesian" },
    { value: "134", label: "Moldovan" },
    { value: "135", label: "Monegasque" },
    { value: "136", label: "Mongolian" },
    { value: "137", label: "Montenegrin" },
    { value: "138", label: "Montserratian" },
    { value: "139", label: "Moroccan" },
    { value: "140", label: "Mosotho" },
    { value: "141", label: "Mozambican" },
    { value: "142", label: "Namibian" },
    { value: "143", label: "Nauruan" },
    { value: "144", label: "Nepalese" },
    { value: "145", label: "New Zealander" },
    { value: "146", label: "Nicaraguan" },
    { value: "147", label: "Nigerian" },
    { value: "148", label: "Nigerien" },
    { value: "149", label: "Niuean" },
    { value: "150", label: "North Korean" },
    { value: "152", label: "Norwegian" },
    { value: "153", label: "Omani" },
    { value: "154", label: "Pakistani" },
    { value: "155", label: "Palauan" },
    { value: "156", label: "Palestinian" },
    { value: "157", label: "Panamanian" },
    { value: "158", label: "Papua New Guinean" },
    { value: "159", label: "Paraguayan" },
    { value: "160", label: "Peruvian" },
    { value: "161", label: "Pitcairn Islander" },
    { value: "162", label: "Polish" },
    { value: "163", label: "Portuguese" },
    { value: "164", label: "Prydeinig" },
    { value: "165", label: "Puerto Rican" },
    { value: "166", label: "Qatari" },
    { value: "167", label: "Romanian" },
    { value: "168", label: "Russian" },
    { value: "169", label: "Rwandan" },
    { value: "170", label: "St Lucian" },
    { value: "171", label: "Salvadorean" },
    { value: "172", label: "Samoan" },
    { value: "173", label: "Sammarinese" },
    { value: "174", label: "Sao Tomean" },
    { value: "175", label: "Saudi Arabian" },
    { value: "177", label: "Senegalese" },
    { value: "178", label: "Serbian" },
    { value: "179", label: "Citizen of Seychelles" },
    { value: "180", label: "Sierra Leonean" },
    { value: "181", label: "Singaporean" },
    { value: "182", label: "Slovak" },
    { value: "183", label: "Slovenian" },
    { value: "184", label: "Solomon Islander" },
    { value: "185", label: "Somali" },
    { value: "186", label: "South African" },
    { value: "187", label: "South Korean" },
    { value: "188", label: "South Sudanese" },
    { value: "189", label: "Spanish" },
    { value: "190", label: "Sri Lankan" },
    { value: "191", label: "St Helenian" },
    { value: "192", label: "Stateless" },
    { value: "193", label: "Sudanese" },
    { value: "194", label: "Surinamese" },
    { value: "195", label: "Swazi" },
    { value: "196", label: "Swedish" },
    { value: "197", label: "Swiss" },
    { value: "198", label: "Syrian" },
    { value: "199", label: "Taiwanese" },
    { value: "200", label: "Tajik" },
    { value: "201", label: "Tanzanian" },
    { value: "202", label: "Thai" },
    { value: "203", label: "Togolese" },
    { value: "204", label: "Tongan" },
    { value: "205", label: "Trinidadian" },
    { value: "206", label: "Tristanian" },
    { value: "207", label: "Tunisian" },
    { value: "208", label: "Turkish" },
    { value: "209", label: "Turkmen" },
    { value: "210", label: "Turks and Caicos Islander" },
    { value: "211", label: "Tuvaluan" },
    { value: "212", label: "Ugandan" },
    { value: "213", label: "Ukrainian" },
    { value: "214", label: "Uruguayan" },
    { value: "215", label: "Uzbek" },
    { value: "216", label: "Citizen of Vanuatu" },
    { value: "217", label: "Vatican citizen" },
    { value: "218", label: "Venezuelan" },
    { value: "219", label: "Vietnamese" },
    { value: "220", label: "Vincentian" },
    { value: "221", label: "Wallisian" },
    { value: "223", label: "Yemeni" },
    { value: "224", label: "Zambian" },
    { value: "225", label: "Zimbabwean" },
    { value: "231", label: "Albanian,British" },
    { value: "232", label: "Albanian,Italian" },
    { value: "233", label: "American,British" },
    { value: "234", label: "American,British,Egyptian" },
    { value: "235", label: "American,Canadian" },
    { value: "237", label: "American,French" },
    { value: "238", label: "American,Ghanaian" },
    { value: "239", label: "American,Greek" },
    { value: "240", label: "American,Irish" },
    { value: "241", label: "American,Israeli\\n" },
    { value: "242", label: "American,Italian" },
    { value: "243", label: "American,Lithuanian" },
    { value: "244", label: "American,New Zealander" },
    { value: "245", label: "American,Pakistani" },
    { value: "246", label: "American,South African" },
    { value: "247", label: "American,Swedish" },
    { value: "248", label: "American,Swiss" },
    { value: "249", label: "American,Thai" },
    { value: "250", label: "Angolan,British" },
    { value: "251", label: "Argentine,Italian" },
    { value: "252", label: "Argentine,Spanish" },
    { value: "253", label: "Australian,British" },
    { value: "254", label: "Australian,Dutch\\n" },
    { value: "255", label: "Australian,French" },
    { value: "256", label: "Australian,Lithuanian" },
    { value: "257", label: "Australian,Polish" },
    { value: "258", label: "Austrian,American" },
    { value: "259", label: "Barbadian,British" },
    { value: "260", label: "Belarusian,British" },
    { value: "261", label: "Belgian,British" },
    { value: "262", label: "Belgian,Dutch" },
    { value: "263", label: "Brazilian,British" },
    { value: "264", label: "Brazilian,Greek,Spanish" },
    { value: "265", label: "Brazilian,Italian" },
    { value: "266", label: "Brazilian,Polish" },
    { value: "267", label: "Brazilian,Portuguese" },
    { value: "331", label: "Bulgarian,British" },
    { value: "332", label: "Bulgarian,Russian" },
    { value: "333", label: "Canadian,American" },
    { value: "334", label: "Canadian,British" },
    { value: "335", label: "Canadian,French" },
    { value: "336", label: "Canadian,Greek" },
    { value: "337", label: "Canadian,Irish" },
    { value: "338", label: "Canadian,Polish" },
    { value: "227", label: "German,British" },
    { value: "339", label: "Hungarian,Israeli" },
    { value: "341", label: "Israeli,American" },
    { value: "342", label: "Israeli,Australian" },
    { value: "343", label: "Israeli,Austrian" },
    { value: "344", label: "Israeli,Belgian" },
    { value: "345", label: "Israeli,British" },
    { value: "346", label: "Israeli,Bulgarian" },
    { value: "347", label: "Israeli,Chinese" },
    { value: "348", label: "Israeli,Croatian" },
    { value: "349", label: "Israeli,Czech" },
    { value: "350", label: "Israeli,Dutch" },
    { value: "351", label: "Israeli,English" },
    { value: "352", label: "Israeli,French" },
    { value: "353", label: "Israeli,German" },
    { value: "355", label: "Israeli,Hungarian" },
    { value: "356", label: "Israeli,Greek" },
    { value: "357", label: "Israeli,Irish" },
    { value: "358", label: "Israeli,Italian" },
    { value: "359", label: "Israeli,Latvian" },
    { value: "360", label: "Israeli,Moldavian" },
    { value: "361", label: "Israeli,Polish" },
    { value: "362", label: "Israeli,Portuguese" },
    { value: "363", label: "Israeli,Romanian" },
    { value: "365", label: "Israeli,Russian" },
    { value: "366", label: "Israeli,Slovak" },
    { value: "367", label: "Israeli,Spanish" },
    { value: "368", label: "Israeli,Ukrainian" },
    { value: "369", label: "Italian,Canadian" },
    { value: "371", label: "Italian,Brazilian" },
    { value: "228", label: "British,American" },
    { value: "268", label: "British,Argentine" },
    { value: "226", label: "British,Australian" },
    { value: "269", label: "British,Austrian" },
    { value: "270", label: "British,Azerbaijani" },
    { value: "271", label: "British,Bahamian" },
    { value: "272", label: "British,Bangladeshi" },
    { value: "273", label: "British,Barbadian" },
    { value: "274", label: "British,Belgian" },
    { value: "275", label: "British,Belgian,Pakistani" },
    { value: "276", label: "British,Bermudian" },
    { value: "277", label: "British,Brazilian" },
    { value: "278", label: "British,Brazilian,Portuguese" },
    { value: "279", label: "British,Canadian" },
    { value: "280", label: "British,Canadian,Swedish" },
    { value: "282", label: "British,Chilean" },
    { value: "283", label: "British,Chinese" },
    { value: "284", label: "British,Colombian" },
    { value: "285", label: "British,Croatian" },
    { value: "286", label: "British,Cypriot" },
    { value: "287", label: "British,Danish" },
    { value: "288", label: "British,Dutch" },
    { value: "289", label: "British,Egyptian" },
    { value: "290", label: "British,Filipino" },
    { value: "291", label: "British,French" },
    { value: "292", label: "British,French,South African" },
    { value: "293", label: "British,Gambian" },
    { value: "294", label: "British,German" },
    { value: "295", label: "British,German,South African" },
    { value: "296", label: "British,Greek" },
    { value: "297", label: "British,Grenadian" },
    { value: "298", label: "British,Guatemalan" },
    { value: "299", label: "British,Hong Konger" },
    { value: "300", label: "British,Hungarian" },
    { value: "229", label: "British,Indian" },
    { value: "301", label: "British,Iranian" },
    { value: "302", label: "British,Irish" },
    { value: "303", label: "British,Israeli" },
    { value: "304", label: "British,Italian" },
    { value: "305", label: "British,Jamaican" },
    { value: "306", label: "British,Kenyan" },
    { value: "307", label: "British,Latvian" },
    { value: "308", label: "British,Luxembourger" },
    { value: "309", label: "British,Malaysian" },
    { value: "310", label: "British,Maltese" },
    { value: "311", label: "British,Mexican" },
    { value: "312", label: "British,New Zealander" },
    { value: "313", label: "British,Nigerian" },
    { value: "314", label: "British,Norwegian" },
    { value: "315", label: "British,Pakistani" },
    { value: "316", label: "British,Polish" },
    { value: "317", label: "British,Portuguese" },
    { value: "318", label: "British,Romanian" },
    { value: "319", label: "British,Russian" },
    { value: "320", label: "British,Serbian" },
    { value: "321", label: "British,Sierra Leonean" },
    { value: "230", label: "British,South African" },
    { value: "322", label: "British,Spanish" },
    { value: "323", label: "British,Sri Lankan" },
    { value: "324", label: "British,Swedish" },
    { value: "325", label: "British,Swiss" },
    { value: "326", label: "British,Taiwanese" },
    { value: "327", label: "British,Thai" },
    { value: "328", label: "British,Trinidadian" },
    { value: "329", label: "British,Turkish" },
    { value: "330", label: "British,Zambian" }
];

nationalities.forEach(x => x.labelLowerCase = x.label.toLowerCase());

import { v4 as uuid } from 'uuid';
import { useMemo } from "react";

export function Checkboxes({ options, checkedArray, onSelect }) {
    const key = useMemo(() => uuid(), []);
    const id = i => `check_${key}_${i}`;

    return (
        <div className="govuk-form-group">
            <div className="govuk-checkboxes">
                {options.map((x, i) => (
                    <div key={i} className="govuk-checkboxes__item">
                        <input
                            className="govuk-checkboxes__input"
                            id={id(i)}
                            type="checkbox"
                            checked={!!checkedArray?.[i]}
                            onChange={() => onSelect(x, i)}
                        />
                        <label className="govuk-label govuk-checkboxes__label" htmlFor={id(i)}>
                            {x}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

//import logo from "../../../assets/images/SadlerandFrost_Logo_light.png";
import logo from "../../../assets/images/logo.svg";

export default function Footer() {
    return (
        <footer className="govuk-footer " role="contentinfo">
            <div className="govuk-width-container ">
                <div className="govuk-footer__meta" style={{ alignItems: "center" }}>
                    <div className="govuk-footer__meta-item govuk-footer__meta-item--grow" >
                        <span className="govuk-footer__licence-description">
                            Copyright {new Date().getFullYear()} © All rights Reserved.
                        </span>
                    </div>
                    <div className="govuk-footer__meta-item">
                        <img src={logo} width={150} />
                    </div>
                </div>
            </div>
        </footer>
    );
}
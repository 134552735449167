import { Input } from "../../lib/components/govuk-frontend/Input";
import { RadioButtons } from "../../lib/components/govuk-frontend/RadioButtons";

export default function OfficerType({ officer, setOfficer, showErrors }) {
    return (
        <>
            <RadioButtons
                label="Officer type"
                hint="Select the type of officer you would like to add"
                options={["Individual", "Corporate"]}
                checkedIndex={officer.isIndividual ? 0 : officer.isCorporate ? 1 : null}
                onSelect={(_, i) => setOfficer({
                    isIndividual: i === 0,
                    isCorporate: i === 1,
                    isPSC: false,
                    isDirector: false,
                    isShareholder: i === 1
                })}
                requiredMessage="Please select the type of the officer"
                showErrors={showErrors}
            />
            {!!officer.isCorporate && (
                <>
                    <Input upperCase
                        id="corporate-name"
                        label="Corporate name"
                        autoComplete="organization"
                        value={officer.corporateName}
                        onChange={corporateName => setOfficer({ corporateName })}
                        requiredMessage="Please enter the corporate name"
                        isInvalid={!officer.corporateName}
                        showErrors={showErrors}
                    />
                    <Input
                        id="registration-number"
                        label="Registration number"
                        value={officer.registrationNumber}
                        onChange={registrationNumber => setOfficer({ registrationNumber })}
                        requiredMessage="Please enter the registration number"
                        isInvalid={!officer.registrationNumber}
                        showErrors={showErrors}
                    />
                </>
            )}
        </>
    );
}
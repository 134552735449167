import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../lib/components/govuk-frontend/Button";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { Details } from "../lib/components/govuk-frontend/Details";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import { Input } from "../lib/components/govuk-frontend/Input";
import Spinner from "../lib/components/govuk-frontend/Snipper";
import { TextError } from "../lib/components/govuk-frontend/TextError";
import { TextSuccess } from "../lib/components/govuk-frontend/TextSuccess";
import { useAuthorizationHeader } from "../lib/helpers/auth";
import { ExistingCompanyFind } from "./ExistingCompanyFind";

ExistingCompanyAuthCode.hasStarted = data => {
    return data?.existingCompanyAuthCode;
}

ExistingCompanyAuthCode.isValid = data => {
    return (data?.isExistingCompanyAuthCodeCorrect || data?.isAuthCodeValidationSkipped);
}
const authCodeLength = 6;

export default function ExistingCompanyAuthCode({ data, setData, onComplete }) {
    const navigate = useNavigate();
    const txtRef = useRef();
    const [result, setResult] = useState(null);
    const [hideCheckAvailability, setHideCheckAvailability] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [companiesHouseServiceIsUnavailable, setCompaniesHouseServiceIsUnavailable] = useState(false);
    const authorizationHeader = useAuthorizationHeader();

    const validateAuthCode = async () => {
        setHideCheckAvailability(true);
        setResult(null);
        setIsLoading(true);
        setCompaniesHouseServiceIsUnavailable(false);

        try {
            const dataJSON = JSON.stringify({
                companyNumber: data.existingCompany?.company_number,
                authCode: data.existingCompanyAuthCode,
            })
            const url = `${process.env.REACT_APP_API_URL}api/onboarding/company/validate_auth_code`;
            const init = {
                headers: { 'Authorization': authorizationHeader, 'Content-Type': 'application/json' },
                method: 'POST',
                body: dataJSON
            };
            const response = await fetch(url, init);
            if (response.status !== 200) {
                throw response;
            }
            const json = await response.json();
            if (json.setting.success != 1) {
                throw response;
            }
            // const data = json.data
            setData({ isExistingCompanyAuthCodeCorrect: json.data.isAuthCodeValid, isAuthCodeValidationSkipped: false });
            setResult(json.data.isAuthCodeValid);
        } catch (error) {
            setCompaniesHouseServiceIsUnavailable(true);
            console.error(error);
        }

        setIsLoading(false);
    }

    const onChange = x => {
        setHideCheckAvailability(false);
        setResult(null);
        setData({ existingCompanyAuthCode: x, isExistingCompanyAuthCodeCorrect: false });
    }

    if (!ExistingCompanyFind.isValid(data)) {
        return <Navigate replace to="/find-your-company" />;
    }

    return (
        <>
            <Heading>AUTH Code</Heading>
            <Input
                reff={txtRef}
                type="password" autoComplete="one-time-code"
                label="Authentication Code"
                hint="Companies House auth code"
                value={data.existingCompanyAuthCode}
                onChange={onChange}
                onSubmit={() => validateAuthCode()}
                readOnly={isLoading}
                inputClass="govuk-input--width-10"
                maxLength={authCodeLength}
            />
            {!!isLoading && (
                <Spinner hint="We are now validating your AUTH code through Companies House services" />
            )}
            {(!!data.isExistingCompanyAuthCodeCorrect && !data.isAuthCodeValidationSkipped) && (
                <>
                    <TextSuccess>
                        Perfect! Your AUTH code is correct.<br />
                    </TextSuccess>
                    <Hint>You can continue to the next step.</Hint>
                </>
            )}
            {!!data.isAuthCodeValidationSkipped && (
                <>
                    <TextSuccess>
                        Perfect!<br />
                    </TextSuccess>
                    <Hint>You can continue to the next step.</Hint>
                </>
            )}
            {(result === false && !data.isAuthCodeValidationSkipped) && (
                <>
                    <TextError>
                        Provided AUTH code is not correct according to Companies House services.
                        <p />
                        <Button secondary onClick={() => { onChange(""); txtRef.current.focus(); }}>Try again</Button>
                    </TextError>
                    <pre>TODO: Not connected to Companies House services yet, enter '123456' to pass.</pre>
                </>
            )}

            {!data.isExistingCompanyAuthCodeCorrect && !hideCheckAvailability && (
                <>
                    <Details
                        summary="What is this?"
                        body={`Your company authentication code is a ${authCodeLength} character alphanumeric code provided by Companies House when your company is formed. It acts as an electronic signature, and it must be provided every time you wish to file electronic documents or make online changes to your company's details.`} />
                    <Button onClick={validateAuthCode} disabled={!data.existingCompanyAuthCode}>Validate AUTH Code</Button>
                </>
            )}

            {!!companiesHouseServiceIsUnavailable && (
                <TextError>
                    Company auth code validator is currently not available.
                    <p />
                    This service is provided by Companies House and probably it is a temporary error.
                    <p />
                    Please try again in a moment.
                    <p />
                    <Button secondary onClick={validateAuthCode}>
                        Try again
                    </Button>
                    <p />
                    {/* <Button secondary onClick={() => { setData({ isExistingCompanyAuthCodeCorrect: true, isAuthCodeValidationSkipped: true }); onComplete(); }}>
                        Skip validation and continue
                    </Button> */}
                </TextError>
            )}
            <br />
            {(ExistingCompanyAuthCode.isValid(data) || data.isAuthCodeValidationSkipped) && (
                <ContinueButton data={data} onContinue={onComplete} />
            )}
            {!data.isAuthCodeValidationSkipped && (
                <Button secondary onClick={() => { setData({ isExistingCompanyAuthCodeCorrect: true, isAuthCodeValidationSkipped: true }); onComplete(); }}>
                    Skip validation and continue
                </Button>
            )}
        </>
    );

}

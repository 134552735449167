import React from 'react';
import "../../../assets/styles/modal.css"

export function Modal({ isOpen, onClose, children }) {
  if (!isOpen) {
    return null;
  }

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={handleModalClick}>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
}
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { useMemo, useState } from "react";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { v4 as uuid } from 'uuid';
import services from "../assets/json/services.json"
import { Details } from "../lib/components/govuk-frontend/Details";


export const plans = [
    {
        header: "Starter",
        price: 60,
        services: services.filter(x => x["Starter"])
    },
    {
        header: "Pro",
        price: 90,
        services: services.filter(x => x["Pro"])
    },
    {
        header: "E-Commerce",
        price: 120,
        services: services.filter(x => x["E-Commerce"])
    },
];

ChoosePlan.hasStarted = data => {
    return !!data.chosenPlan;
}

ChoosePlan.isValid = data => {
    return !!data.chosenPlan;
}

export function ChoosePlan({ data, setData, onComplete }) {

    const [showErrors, setShowErrors] = useState(false);

    const validate = () => {
        if (!ChoosePlan.isValid(data)) {
            setShowErrors(new Date().getTime());
            return false;
        }
        return true;
    }

    const key = useMemo(() => uuid(), []);
    const id = i => `radio_${key}_${i}`;
    const isInvalid = false;//!!(showErrors && requiredMessage && checkedIndex == null);

    return (
        <>
            <Heading>Choose Plan</Heading>
            <div className={"govuk-form-group" + (isInvalid ? " govuk-form-group--error" : "")}>
                {plans.map((x, i) => (
                    <div key={i}>
                        <div className="govuk-radios">
                            <div className="govuk-radios__item">
                                <input
                                    className="govuk-radios__input"
                                    id={id(i)}
                                    type="radio"
                                    checked={data.chosenPlan === x.header}
                                    onChange={() => setData({ chosenPlan: x.header })}
                                />
                                <label className="govuk-label govuk-radios__label" htmlFor={id(i)}>
                                    <b>{x.header} </b>
                                    <br />
                                    £{x.price} per month
                                </label>

                                <ul className="govuk-list govuk-list--tick" >
                                    {!!i && <li>Everything from {plans[i - 1].header}</li>}
                                    {x.services.map((s, si) => (
                                        <li key={si}>
                                            {s["Service Name"]}
                                            {/* {s["Description"] && (
                                                <Details inline
                                                    summary="Description"
                                                    body={<div dangerouslySetInnerHTML={{ __html: s["Description"] }} />}
                                                />
                                            )} */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <ContinueButton data={data} validate={validate} onContinue={onComplete} />
        </>
    );
}

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import { Input } from "../lib/components/govuk-frontend/Input";
import TextInset from "../lib/components/govuk-frontend/TextInset";
import { useSessionId } from "../lib/helpers/auth";
import { useAuthorizationHeader } from "../lib/helpers/auth";

export const isEmailValid = (email) => {
    return !!(String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
};

export const isPhoneValid = (phone) => {
    return !!(String(phone)
        .toLowerCase()
        .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
        ));
}

ContactDetails.hasStarted = data => {
    return data?.contactEmail || data?.contactPhone;
}

ContactDetails.isValid = data => {
    return isEmailValid(data?.contactEmail)
        && isPhoneValid(data?.contactPhone);
}

export default function ContactDetails({ data, setData, onComplete }) {

    const authorizationHeader = useAuthorizationHeader();
    const sessionId = useSessionId();
    const [showErrors, setShowErrors] = useState(useSearchParams()[0].has("showErrors"));

    const validate = () => {
        const email = data.contactEmail?.toLowerCase().replaceAll(" ", "") || "";

        if (email !== data.contactEmail) {
            setData({ contactEmail: email });
        }
        if (!ContactDetails.isValid(data)) {
            setShowErrors(new Date().getTime());
            return false;
        }

        setData({ contactEmailConfirmed: true });
        return true;
    }

    return (
        <>
            <Heading>Contact Details</Heading>
            <Hint>
                Enter an email address so you can save your answers. You will then be emailed a unique link to let you log back in to your application at a later point, if needed.
                <br /><br />
                Throughout your application, you can select the option to 'Save & Continue later' in order to re-send the link to your latest saved application.
            </Hint>
            <br />
            <Input
                label="Your full name"
                value={data.contactFullName}
                onChange={contactFullName => { setData({ contactFullName }); }}
                id="fullname"
                name="fullname"
                requiredMessage="Please enter the full name"
                isInvalid={(!data.contactFullName)}
                showErrors={showErrors}
            />
            <Input
                label="Your e-mail address"
                hint="We’ll only use this to send you company documents or to help you continue filling this form later"
                value={data.contactEmail}
                onChange={x => { setData({ contactEmail: x.replaceAll(" ", ""), contactEmailConfirmed: false }); }}
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                requiredMessage="Please enter a valid email address"
                isInvalid={!isEmailValid(data?.contactEmail)}
                showErrors={showErrors}
            />
            {!!data.contactEmail && (
                <TextInset>
                    <Hint>An email will be sent to:</Hint>
                    <strong>{data.contactEmail}</strong>
                </TextInset>
            )}
            <Input
                label="Your phone number"
                hint="Please add the country code if not a UK number"
                value={data.contactPhone}
                onChange={contactPhone => setData({ contactPhone })}
                id="tel"
                type="tel"
                name="tel"
                autoComplete="tel"
                inputClass="govuk-input--width-10"
                format={x => x.replace(/[^\d\+]/, '')}
                maxLength={13}
                requiredMessage="Please enter a valid phone number"
                isInvalid={!isPhoneValid(data?.contactPhone)}
                showErrors={showErrors}
            />
            {!!data.contactPhone && (
                <TextInset>
                    <Hint>We will contact you on <strong>{data.contactPhone}</strong> after the submission of your application either to confirm your registration, or to ask for more information.</Hint>
                </TextInset>
            )}
            <br />
            <ContinueButton data={data} label="Save and continue" validate={validate} onContinue={onComplete} />
            <p>
                <code>Session ID: {sessionId}</code>
            </p>
        </>
    );
}
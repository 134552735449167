import { Checkboxes } from "../../lib/components/govuk-frontend/Checkboxes";
import { Details } from "../../lib/components/govuk-frontend/Details";
import { Hint } from "../../lib/components/govuk-frontend/Hint";
import { Input } from "../../lib/components/govuk-frontend/Input";
import { Label } from "../../lib/components/govuk-frontend/Label";

export default function OfficerRole({ officer, setOfficer, showErrors }) {
    return (
        <div className={"govuk-form-group" + ((showErrors && !officer.isPSC && !officer.isDirector && !officer.isShareholder) ? " govuk-form-group--error" : "")}>
            <Label>Role</Label>
            <Hint>Select the role(s) they will hold. You may select more than one. </Hint>
            {(showErrors && !officer.isPSC && !officer.isDirector && !officer.isShareholder) && (
                <p className="govuk-error-message">
                    <span className="govuk-visually-hidden">Error:</span> An officer must have at least one role.
                </p>
            )}
            {officer.isIndividual && (
                <>
                    <Checkboxes
                        options={["PSC"]}
                        checkedArray={[officer.isPSC]}
                        onSelect={(x, i) => setOfficer({ isPSC: !officer.isPSC })}
                    />
                    <Details
                        summary="What is PSC?"
                        body={(
                            <>
                                <b>P</b>erson with <b>S</b>ignificant <b>C</b>ontrol.
                                <br />
                                PSC is someone who controls your company.
                                This might be you, or someone associated with your company. A company can have one or more PSCs.
                            </>
                        )}
                    />
                    <br />
                </>
            )}
            {officer.isIndividual && (
                <>
                    <Checkboxes
                        options={["Director"]}
                        checkedArray={[officer.isDirector]}
                        onSelect={(x, i) => setOfficer({ isDirector: !officer.isDirector })}
                    />
                    <Details
                        summary="What is Director?"
                        body={(
                            <>
                                A director is a person, natural or corporate entity, appointed by the company members to run the company on their behalf. A director can act as both director and secretary. All companies must have at least one natural person director that is at least 16 years of age.
                            </>
                        )}
                    />
                    <br />
                </>
            )}
            <>
                <Checkboxes
                    options={["Shareholder"]}
                    checkedArray={[officer.isShareholder]}
                    onSelect={(x, i) => setOfficer({ isShareholder: !officer.isShareholder })}
                />
                <Details
                    summary="What is Shareholder?"
                    body={(
                        <>
                            A shareholder is an individual or entity owning shares in a company, thus having a stake in its assets and earnings.
                        </>
                    )}
                />
                <br />
            </>
            <div></div>
            {!!officer.isShareholder && (
                <Input
                    pattern="[^0-9]" //type="number"
                    label="Number of shares this officer holds"
                    hint="This is the value which is unpaid on total amount of share. This can be any whole number, but in most cases it will be 100 (e.g. 100 of 100 and sole shareholder holds 100% of shares). Company's total number of shares will be the sum of all shares held by all shareholders."
                    value={officer.numberOfShares}
                    format={x => x.replace(/\D/g, '').replace(/^0+/, '').substring(0, 11)}
                    onChange={numberOfShares => setOfficer({ numberOfShares })}
                    requiredMessage="Please enter the number of shares this officer holds"
                    isInvalid={!parseInt(officer.numberOfShares)}
                    showErrors={showErrors}
                />
            )}
        </div>
    );
}
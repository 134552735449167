import { useMemo } from "react";
import { Link } from "react-router-dom";
import { LeadForm } from "../../../pages/LeadForm";
import { useWindowDimensions } from "../../helpers/window-dimensions";

export const TaskStatus = {
    NOT_STARTED: {
        label: "Not Started",
        className: "govuk-tag--grey",
        isClickable: true,
    },
    INCOMPLETE: {
        label: "Incomplete",
        className: "govuk-tag--blue",
        isClickable: true,
    },
    COMPLETED: {
        label: "Completed",
        className: "",
        isClickable: true,
    },
    CAN_NOT_START_YET: {
        label: "Can not start yet",
        className: "govuk-tag--grey",
        isClickable: false,
    }
}

export function StatusTag({ status, floatLeft }) {
    const { isSmallWindow } = useWindowDimensions();
    return (
        <strong
            className={"govuk-tag app-task-list__tag " + status?.className}
            style={{ whiteSpace: "nowrap", marginBottom: 5, marginTop: isSmallWindow ? 10 : 0, float: floatLeft && "left" }}
        >
            {status?.label}
        </strong>
    );
}

export function TaskList({ data, sections }) {

    const totalSections = useMemo(() => sections.map(x => x.items.length).reduce((a, b) => a + b, 0), [sections]);
    const completedSections = useMemo(() => sections.map(x => x.items.filter(y => y.status === TaskStatus.COMPLETED).length).reduce((a, b) => a + b, 0), [sections]);

    return (
        <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-thirds">
                <h1 className="govuk-heading-xl">
                    {LeadForm.getTitle(data)}
                </h1>

                <h2 className="govuk-heading-s govuk-!-margin-bottom-2">Application incomplete</h2>
                <p className="govuk-body govuk-!-margin-bottom-7">You have completed {completedSections} of {totalSections} steps.</p>

                <ol className="app-task-list">
                    {sections.map((s, si) => (
                        <li key={si}>
                            <h2 className="app-task-list__section">
                                <span className="app-task-list__section-number">{si + 1}. </span> {s.header}
                            </h2>
                            <ul className="app-task-list__items">
                                {s.items.map((x, xi) => (
                                    <li key={xi} className="app-task-list__item">
                                        <span className="app-task-list__task-name">
                                            {x.status?.isClickable ? (
                                                <Link to={x.path || "/"} onClick={() => window.scrollTo(0, 0)}>
                                                    {x.label}
                                                </Link>
                                            ) : (
                                                x.label
                                            )}
                                        </span>
                                        <StatusTag status={x.status} />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
}

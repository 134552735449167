import { useSearchParams } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import { useMemo } from "react";

const STR_SESSION_ID = "sid";

export function useSessionId() {
    const sessionIdFromSearchParams = useSearchParams()[0].get(STR_SESSION_ID);

    return useMemo(() => {
        const sid = sessionIdFromSearchParams
            || localStorage.getItem(STR_SESSION_ID)
            || uuid();

        localStorage.setItem(STR_SESSION_ID, sid);

        return sid;
    }, [sessionIdFromSearchParams]);
}

export function useAuthorizationHeader() {
    const sessionId = useSessionId();

    return useMemo(() => {
        const token = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(sessionId),
            CryptoJS.enc.Utf8.parse("-SnF-SrC_AyZ_TyK"),
            { mode: CryptoJS.mode.ECB }).toString();

        return "Basic " + btoa(sessionId + ":" + token);
    }, [sessionId]);
}

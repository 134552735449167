import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useMemo } from "react";
import { Hint } from "./Hint";
import { Label } from "./Label";
import { Explanation } from './Explanation';

function removeTrChars(str) {
    return str
        .replace(/ı/g, "i")
        .replace(/ğ/g, "g")
        .replace(/ü/g, "u")
        .replace(/ş/g, "s")
        .replace(/ö/g, "o")
        .replace(/ç/g, "c")
        .replace(/İ/g, "I")
        .replace(/Ğ/g, "G")
        .replace(/Ü/g, "U")
        .replace(/Ş/g, "S")
        .replace(/Ö/g, "O")
        .replace(/Ç/g, "C");
}

export const lastUsedShowErrors = {
    value: null,
};

export function Input({ reff, id,
    value, type, format, autoFocus, onChange, onSubmit, onKeyDown, onBlur, readOnly, smallLabel, label, hint, explanation, prefix, suffix,
    placeholder, name, selectOnFocus, maxLength, list, requiredMessage, isInvalid, showErrors, inputClass, autoComplete, pattern, inputMode,
    dataAutomationId, upperCase, onFocus
}) {
    const ref = useRef();
    const inputId = useMemo(() => id || uuid(), []);
    const listId = useMemo(() => uuid(), []);
    const [caretPos, setCaretPos] = useState();

    const errorVisible = isInvalid && showErrors; // showErrors must be in the end because it's a timestamp

    useImperativeHandle(reff, () => ({
        focus: () => {
            ref.current?.focus?.();
        },
        blur: () => {
            ref.current?.blur?.();
        }
    }));

    useEffect(() => {
        if (errorVisible && showErrors !== lastUsedShowErrors.value) {
            lastUsedShowErrors.value = showErrors;
            ref.current?.focus?.();
        }
    }, [errorVisible]);

    useEffect(() => {
        if (caretPos != undefined && ref.current && ref.current.type !== "email" && ref.current.selectionStart !== caretPos) {
            ref.current.setSelectionRange(caretPos, caretPos);
        }
    }, [ref, caretPos, value]);

    const handleChange = e => {
        setCaretPos(e.target.selectionStart);

        let val = removeTrChars(e.target.value).trimStart().replace(/ +/g, " ");
        if (pattern) {
            val = val.replace(new RegExp(pattern, 'g'), '');
        }
        if (typeof (format) === "function") {
            val = format(val);
        }
        if (upperCase) {
            val = val.toUpperCase();
        }
        onChange?.(val);
    }

    const handleBlur = e => {
        onBlur?.(e);
        const val = (value || "").replace(/  +/g, ' ').trim();
        if (val != value) {
            onChange(val);
        }
    }

    const handleFocus = e => {
        onFocus?.(e);
    }

    return (
        <div className={"govuk-form-group" + (errorVisible ? " govuk-form-group--error" : "")}>
            {!!label && (
                <Label htmlFor={inputId} smallLabel={smallLabel}>
                    {label}
                </Label>
            )}
            {!!hint && (
                <Hint>
                    {hint}
                </Hint>
            )}
            {errorVisible && requiredMessage && (
                <p className="govuk-error-message">
                    <span className="govuk-visually-hidden">Error:</span> {requiredMessage}
                </p>
            )}

            {!!list?.length && (
                <datalist id={listId}>
                    {list.map((x, i) => (
                        <option key={i}>{x}</option>
                    ))}
                </datalist>
            )}
            <div className="govuk-input__wrapper">
                {!!prefix && <div className="govuk-input__prefix" aria-hidden="true">{prefix}</div>}
                <input
                    ref={ref}
                    id={inputId}
                    className={"govuk-input " + (inputClass || "") + (errorVisible ? " govuk-input--error" : "")}
                    name={name}
                    type={type || "text"}
                    autoFocus={autoFocus}
                    value={value || ""}
                    onInput={handleChange}
                    onKeyDown={e => {
                        e.key === "Enter" && onSubmit?.();
                        type === "number" && ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault();
                        onKeyDown?.(e.key);
                    }}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    onFocus={selectOnFocus ? e => setTimeout(() => e.target.select(), 10) : handleFocus}
                    maxLength={maxLength}
                    list={listId}
                    spellCheck={false}
                    autoComplete={autoComplete}
                    pattern={pattern}
                    inputMode={inputMode}
                    data-automation-id={dataAutomationId}
                />
                {!!suffix && <div className="govuk-input__suffix" aria-hidden="true">{suffix}</div>}
            </div>
            {!!explanation && (
                <Explanation>
                    {explanation}
                </Explanation>
            )}
        </div>
    );
};

import { useState } from "react";
import { Button } from "../lib/components/govuk-frontend/Button";
import { Details } from "../lib/components/govuk-frontend/Details";
import FileUpload from "../lib/components/govuk-frontend/FileUpload";
import { Input } from "../lib/components/govuk-frontend/Input";
import TextInset from "../lib/components/govuk-frontend/TextInset";
import { TextSuccess } from "../lib/components/govuk-frontend/TextSuccess";
import { TextWarning } from "../lib/components/govuk-frontend/TextWarning";
import { useSessionId } from "../lib/helpers/auth";
import { officerName } from "./officer/officer-helper-functions";

PaymentSuccessful.isValid = data => {

    if (data?.isNewCompany) {
        return data?.officers?.filter(x => !x.proofOfIdDocument || !x.proofOfAddressDocument).length === 0;
    }

    if (data?.isExistingCompany) {
        return data?.existingCompanyAuthCode?.length === 6
            && (data?.isExistingCompanyVatRegistered_No || !!data?.existingCompanyVatCertificateDocument);
    }
}

PaymentSuccessful.path = "payment-successful";

const authCodeLength = 6;

export default function PaymentSuccessful({ data, setData, onComplete }) {

    const sessionId = useSessionId();
    const [showErrors, setShowErrors] = useState();

    const validate = () => {
        if (!PaymentSuccessful.isValid(data)) {
            setShowErrors(new Date().getTime());
            return false;
        }

        return true;
    }

    const setOfficer = (i, x) => {
        setData(oldData => {
            const officers = [...oldData.officers];
            officers[i] = { ...officers[i], ...x };
            return { officers };
        });
    }


    return (
        <div className="govuk-grid-column-two-thirds-from-desktop">
            <TextSuccess>
                Payment successful!<br />
                <small><code>{sessionId}</code></small>
            </TextSuccess>
            <h2 className="govuk-heading-m">What is next?</h2>
            <p className="govuk-body">
                We'll start your onboarding process right now. But in order to complete this, we need some more information from you.
            </p>

            <TextWarning>
                Please provide the following information as soon as is convenient for you.
                <br />
                <i><small>*You can also return back to this page later by clicking on the link we sent to your email address.</small></i>
            </TextWarning>

            {
                data.isNewCompany && data.officers.map((o, i) => (
                    <div key={i}>
                        <FileUpload
                            label={"Proof of ID"}
                            hint={"for " + officerName(data, i)}
                            explanation={"*Please upload passport or UK driving licence"}
                            requiredMessage="Please upload proof of ID"
                            isInvalid={!o.proofOfIdDocument}
                            showErrors={showErrors}
                            value={o.proofOfIdDocument}
                            onChange={x => setOfficer(i, { proofOfIdDocument: x })}
                        />
                        <FileUpload
                            label={"Proof of address"}
                            hint={"for " + officerName(data, i)}
                            explanation={"*Please upload bank statement or utility bill"}
                            requiredMessage="Please upload proof of address"
                            isInvalid={!o.proofOfAddressDocument}
                            showErrors={showErrors}
                            value={o.proofOfAddressDocument}
                            onChange={x => setOfficer(i, { proofOfAddressDocument: x })}
                        />
                    </div>
                ))
            }


            {
                data.isExistingCompany && (
                    <>
                        <Input
                            label="Auth Code"
                            hint="Companies House authentication code of your company"
                            explanation={"*Auth code of " + data.existingCompany.title}
                            inputClass="govuk-input--width-10"
                            maxLength={authCodeLength}
                            value={data.existingCompanyAuthCode}
                            onChange={x => setData({ existingCompanyAuthCode: x.replaceAll(" ", "").toUpperCase() })}
                            requiredMessage="Please enter your company's auth code"
                            isInvalid={data.existingCompanyAuthCode?.length !== authCodeLength}
                            showErrors={showErrors}
                        />
                        <Details
                            summary="What is Auth Code?"
                            body={(
                                <>
                                    {/* <p>Your company authentication code is a {authCodeLength} character alphanumeric code provided by Companies House when your company is formed. It acts as an electronic signature, and it must be provided every time you wish to file electronic documents or make online changes to your company's details.</p> */}
                                    <p>The authentication code is a {authCodeLength} digit alphanumeric code issued by Companies House to each company. The code is used to authorise information filed online and is the equivalent of a company officer's signature.</p>
                                    <p>We need this code to represent you as your accountant. Please keep your auth code safe.</p>
                                    <p>If you don't have your auth code you can ask your current accountant for this, or click the button below to request your auth code to be sent to your company's registered address by Companies House.</p>
                                    <Button onClick={() => alert("TODO: request auth code")}>Request Auth Code</Button>
                                </>
                            )}
                        />
                        {data.isExistingCompanyVatRegistered_Yes && (
                            <>
                                <FileUpload
                                    label={"VAT Certificate"}
                                    hint="Please upload your company's VAT certificate"
                                    explanation={"*VAT Certificate of " + data.existingCompany.title}
                                    requiredMessage="Please upload you company's VAT certificate"
                                    isInvalid={!data.existingCompanyVatCertificateDocument}
                                    showErrors={showErrors}
                                    value={data.existingCompanyVatCertificateDocument}
                                    onChange={x => setData({ existingCompanyVatCertificateDocument: x })}
                                />
                                <Details
                                    summary="What is VAT Certificate?"
                                    body="A VAT certificate is a document that HMRC provides to confirm that your business is officially registered for VAT. A VAT certificate includes: Your business's unique VAT number. The effective date of registration (the date from which VAT needs to be charged on VATable sales)"
                                />

                            </>
                        )}
                    </>
                )
            }
            <br />
            <Button onClick={() => validate() && onComplete()}>
                Submit Application
            </Button>

            <hr />
            <p style={{ fontSize: 16, color: "#6f777b", lineHeight: 1.125 }}>
                If you have any questions please contact us via,
                <br /><br />
                Contact Centre tel <a href="tel:+44 203 488 4747">+44 203 488 4747</a>
                <br /><br />
                or email <a href='mailto:info@sadlerandfrost.com' target='_blank'>info@sadlerandfrost.com</a>
            </p>
            <button onClick={() => setData({ isPaid: false })}>unpay</button>
        </div>
    );
}
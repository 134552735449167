import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Routes, Route, Link, useLocation, useNavigate, Navigate, useSearchParams } from "react-router-dom";
import Header from "./lib/components/govuk-frontend/Header";
import { TaskList, TaskStatus } from "./lib/components/govuk-frontend/TaskList";
import ContactDetails from "./pages/ContactDetails";
import NewCompanyAddress from "./pages/NewCompanyAddress";
import NewCompanyName from "./pages/NewCompanyName";
import NewCompanyOfficer from "./pages/officer/NewCompanyOfficer";
import NewCompanyOfficers from "./pages/officer/NewCompanyOfficers";
import { NewCompanyPlannedBusinessActivitiesForm } from "./pages/NewCompanyPlannedBusinessActivitiesForm";
import Summary from "./pages/Summary";
import { ChoosePlan } from "./pages/ChoosePlan";
import { LeadForm } from "./pages/LeadForm";
import { ExistingCompanyFind } from "./pages/ExistingCompanyFind";
import { ExistingCompanyVAT } from "./pages/ExistingCompanyVAT";
import debounce from 'lodash/debounce';
import { useAuthorizationHeader } from "./lib/helpers/auth";
import SubmitAndPay from "./pages/SubmitAndPay";
import Spinner from "./lib/components/govuk-frontend/Snipper";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import LoadingBar from 'react-top-loading-bar';
import ExistingCompanyAuthCode from "./pages/ExistingCompanyAuthCode";
import Footer from "./lib/components/govuk-frontend/Footer";
import ApplicationComplete from "./pages/ApplicationComplete";
import NotFound from "./pages/NotFound";
import { Toaster } from "sonner";

function getSectionsForExistingCompany() {
    return [
        {
            header: "Find your company",
            items: [
                {
                    label: "Find your company",
                    Component: ExistingCompanyFind,
                },
                {
                    label: "Contact Details",
                    Component: ContactDetails,
                    isSaveAndContinue: true,
                },
            ]
        },
        {
            header: "Company details",
            items: [
                {
                    label: "VAT Details",
                    Component: ExistingCompanyVAT,
                },
                // {
                //     label: "Auth Code",
                //     Component: ExistingCompanyAuthCode,
                // },
            ]
        }
    ];
}

function getSectionsForNewCompany() {
    return [
        {
            header: "Company details",
            items: [
                {
                    label: "Company name",
                    Component: NewCompanyName,
                },
                {
                    label: "Planned business activities",
                    Component: NewCompanyPlannedBusinessActivitiesForm,
                },
                {
                    label: "Registered office address",
                    Component: NewCompanyAddress,
                },
            ]
        },
        {
            header: "People details",
            items: [
                {
                    label: "Contact Details",
                    Component: ContactDetails,
                    isSaveAndContinue: true,
                },
                {
                    label: "Officers",
                    Component: NewCompanyOfficers,
                    alternativePathNamePrefix: "/officer/",
                },
            ]
        }
    ];
}

function getSections(data, setAllCompleted) {

    const sections = [
        {
            header: "Welcome",
            items: [
                {
                    label: "Do you already have a company?",
                    Component: LeadForm,
                },
            ]
        }
    ];

    const midSections = (data.isNewCompany
        ? getSectionsForNewCompany(data, setAllCompleted)
        : data.isExistingCompany ? getSectionsForExistingCompany(data, setAllCompleted)
            : []);

    sections.push(...midSections);

    // TODO: choose plan & choose add-ons
    // LeadForm.isValid && sections.push({
    //     header: "Services",
    //     items: [
    //         {
    //             label: "Choose plan",
    //             Component: ChoosePlan,
    //         },
    //         {
    //             label: "Choose add-ons",
    //             Component: ChoosePlan, // TODO: add-ons
    //         },
    //     ]
    // });

    let allCompleted = true;

    sections.forEach(s => {
        s.items.forEach(x => {
            const c = x.Component;
            x.status = c.isValid?.(data) ? TaskStatus.COMPLETED
                : c.hasStarted?.(data) ? TaskStatus.INCOMPLETE
                    : TaskStatus.NOT_STARTED;

            if (x.status !== TaskStatus.COMPLETED) {
                allCompleted = false;
            }
        });
    });

    setAllCompleted(allCompleted);

    LeadForm.isValid(data) && sections.push(
        {
            header: "Apply",
            items: [
                {
                    label: "Summary",
                    Component: Summary,
                    isSummary: true,
                    status: !allCompleted ? TaskStatus.CAN_NOT_START_YET
                        : !data.isConfirmed ? TaskStatus.INCOMPLETE
                            : TaskStatus.COMPLETED
                },
                // {
                //     label: "Submit and pay",
                //     Component: SubmitAndPay,
                //     status: !data.isConfirmed ? TaskStatus.CAN_NOT_START_YET
                //         : TaskStatus.INCOMPLETE
                // },
            ]
        }
    );

    sections.forEach(s =>
        s.items.forEach(x =>
            x.path = x.Component?.path || encodeURIComponent(x.label.toLowerCase().replace(/[^a-z0-9_-]+/gi, ' ').trim().replaceAll(' ', '-'))
        )
    );

    return sections;
}

export default function App() {

    const authorizationHeader = useAuthorizationHeader();
    const [searchParams] = useSearchParams();
    const customerID = searchParams.get("customerID")
    const [data, setData] = useState();


    const dataRef = useRef(data);
    const lastSavedAt = useRef(0);
    const lastChangedAt = useRef(0);

    const loadingBar = useRef();

    useEffect(() => {
        if (customerID) {
            localStorage.setItem("customerID", customerID)
        }
    }, [])

    //#region persist state

    useEffect(() => {
        load();
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    useEffect(() => {
        dataChanged(data);
    }, [data]);

    const handleBeforeUnload = useCallback(e => {
        if (dataRef?.current?.isConfirmed) {
            return;
        }

        if (lastChangedAt.current > lastSavedAt.current) {
            saveImmediately(lastChangedAt.current);
            e.preventDefault();
            e.returnValue = ''; //required for Chrome
        }
    }, []);

    const load = useCallback(() => {

        let customerID = localStorage.getItem("customerID")
        const dataJSON = JSON.stringify({ customerID: customerID });
        let url = `${process.env.REACT_APP_API_URL}api/onboarding/company/get_company`
        const init = {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: dataJSON,
        }
        fetch(url, init).then(response => {
            if (response.status == 200) {
                response.json().then((resData) => {
                    let newData = resData.data
                    setData({ ...newData, customerID })
                    // setData({ ...data, newData })
                });
            } else {
                setData({ customerID });
            }
        }, reason => {
            console.error("Could not load session from server.", reason);
            alert("Could not load session from server. Please try again.");
            setData({});
        });

    }, []);

    const firstFlag = useRef(true);
    const dataChanged = useCallback((data) => {
        if (!data) {
            return;
        }
        if (firstFlag.current) {
            firstFlag.current = false;
            return;
        }

        const title = LeadForm.getTitle(data) + " | Sadler & Frost";
        if (document.title !== title) {
            document.title = title;
        }

        dataRef.current = data;
        lastChangedAt.current = new Date().getTime();
        saveDebounce(lastChangedAt.current);
    }, []);

    const saveDebounce = useMemo(() => debounce((timeStamp) => {
        saveImmediately(timeStamp);
    }, 5000), []);

    const saveImmediately = useCallback((timeStamp) => {
        if (dataRef?.current?.isConfirmed) {
            return;
        }

        const data = dataRef.current;
        const dataJSON = JSON.stringify(data);
        const input = `${process.env.REACT_APP_API_URL}api/onboarding/company/session`;
        const init = {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: dataJSON,
        }

        loadingBar.current.continuousStart();
        fetch(input, init).then(response => {
            if (response.status == 200) {
                lastSavedAt.current = timeStamp;
                loadingBar.current.complete();
            } else {
                saveError(response);
            }
        }, saveError);
    }, []);

    const saveError = useCallback(reason => {
        console.error("Could not save current state to the server", reason);
        saveDebounce(lastChangedAt.current);
    });

    //#endregion

    if (!data) {
        return null;
        return <Spinner hint="Sadler and Frost onboarding wizard..." />
    }

    return (
        <>
            <LoadingBar ref={loadingBar} />
            <Pages data={data} setData={setData} />
        </>
    );
}

function Pages({ data, setData }) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (data.isConfirmed) {
            return;
        }

        // if (!data.customerID) {
        //     navigate("/notfound");
        //     return;
        // }

        if (location.pathname === "/new-company") {
            handleSetData({ isNewCompany: true, isExistingCompany: false });
            navigate(`${LeadForm.path}?customerID=${data.customerID}` || "/");
            return;
        }
        if (location.pathname === "/existing-company") {
            handleSetData({ isNewCompany: false, isExistingCompany: true });
            navigate(`${LeadForm.path}?customerID=${data.customerID}` || "/");
            return;
        }
        if (!LeadForm.isValid(data)) {
            navigate(`${LeadForm.path}?customerID=${data.customerID}` || "/");
            return;
        }
        if (data.isNewCompany ? !data.companyName : !data.existingCompany) {
            navigate(steps[1]?.path || "/");
            return;
        }
    }, []);

    const handleSetData = (newData) => {
        setData(oldData => {
            if (typeof (newData) === "function") {
                newData = newData(oldData);
            }
            return {
                ...oldData,
                ...newData,
                isConfirmed: newData.isConfirmed || false
            };
        });
    };

    const setAllCompleted = x => {
        if (!!data.allCompleted !== !!x) {
            setTimeout(() => {
                handleSetData({ allCompleted: x, isConfirmed: false });
            }, 10);
        }
    }

    const sections = useMemo(() => getSections(data, setAllCompleted), [data]);
    const steps = useMemo(() => sections.map(x => x.items).flat(), [sections]);
    const step = useMemo(() => steps.findIndex(x => location.pathname === `/${x.path}` || location.pathname.startsWith(x.alternativePathNamePrefix)), [location.pathname]);

    if (data.isConfirmed && location.pathname !== "/" + ApplicationComplete.path) {
        window.scrollTo(0, 0);
        return <Navigate to={ApplicationComplete.path} />;
    }

    if (data.isPaid && location.pathname !== "/" + PaymentSuccessful.path) {
        window.scrollTo(0, 0);
        return <Navigate to={PaymentSuccessful.path} />;
    }

    return (
        <>
            <Header data={data} title={LeadForm.getTitle(data)} />
            <Container step={step} steps={steps} data={data}>
                <Routes>
                    <Route
                        path="/" element={
                            <TaskList
                                data={data}
                                sections={sections}
                            />
                        }
                    />
                    {steps.map(({ Component, path }, i) => Component && (
                        <Route key={i} path={path} element={
                            <Component
                                data={data}
                                setData={handleSetData}
                                onComplete={() => {

                                    if (step == steps.length - 1) {
                                        navigate("/");
                                        return;
                                    }

                                    let showErrors = false;
                                    let nextStep = steps[step + 1];
                                    if (nextStep.isSummary && !data.allCompleted) {
                                        nextStep = steps.find(x => x.status !== TaskStatus.COMPLETED);
                                        showErrors = true;
                                    }
                                    navigate(nextStep.path + (showErrors ? "?showErrors" : ""));
                                    window.scrollTo(0, 0);
                                }}
                            />}
                        />))}
                    <Route
                        path="/officer/:officerIndex/:tabIndex"
                        element={
                            <NewCompanyOfficer
                                data={data}
                                setData={handleSetData}
                                onComplete={() => {
                                    navigate("/officers");
                                }}
                            />
                        }
                    />
                    <Route
                        path={"/" + ApplicationComplete.path}
                        element={<ApplicationComplete data={data} />}
                    />
                    {/* <Route
                        path={"/notfound"}
                        element={<NotFound />}
                    /> */}
                    {/* <Route
                        path={"/" + PaymentSuccessful.path}
                        element={
                            <PaymentSuccessful
                                data={data}
                                setData={handleSetData}
                                onComplete={() => {
                                    alert("TODO: show application complete page");
                                }}
                            />
                        }
                    /> */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Container>
            <Footer />
        </>
    );
}

const Container = ({ children, step, steps, data }) => {
    const location = useLocation();
    const findPath = useCallback(filterFnc => steps.filter(filterFnc).map(x => x.path)[0], [steps]);
    const pathSaveAndContinue = useMemo(() => findPath(x => x.isSaveAndContinue), [steps]);

    return (
        <div className="govuk-width-container">
            {step <= 0 ? (<>&nbsp;</>) : (
                <Link to="/" className="govuk-back-link" id="back-link">
                    Step {step + 1} of {steps.length}
                </Link>
            )}
            {(step <= 0 || location.pathname === `/contact-details`) ? (<>&nbsp;</>) : (
                <Link to={pathSaveAndContinue} className="govuk-link" style={{ float: "right", fontSize: 14, marginTop: 15, lineHeight: 1.1428571429, display: "inline-block", color: "#0b0c0c" }}>
                    Save & Continue Later
                </Link>
            )}
            <main
                className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
                id="main-content"
                role="main"
            >
                <div className="govuk-grid-row">
                    <div className="govuk-grid-column-full">
                        <div className="govuk-!-margin-bottom-6">

                            {false && (
                                <pre style={{ borderWidth: 1, borderColor: "red", borderStyle: "solid", padding: 20, position: "absolute", right: 16, top: 110, fontSize: 10, width: 250 }}>{JSON.stringify(data, null, 4)}</pre>
                            )}

                            {children}
                        </div>
                    </div>
                </div>
            </main>
            <Toaster position='top-center' />
        </div>
    );
}


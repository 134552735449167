import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAuthorizationHeader } from '../../helpers/auth';
import { Hint } from './Hint';
import { Label } from './Label';
import axios from 'axios';
import ProgressBar from "@ramonak/react-progress-bar";
import Link1 from './Link1';
import { Explanation } from './Explanation';
import { lastUsedShowErrors } from './Input';

export default function FileUpload({ id, smallLabel, label, hint, explanation, value, onChange, requiredMessage, isInvalid, showErrors }) {
    const ref = useRef();
    const authorizationHeader = useAuthorizationHeader();
    const inputId = useMemo(() => id || ("file-upload-" + uuid()), []);
    const [selectedFile, setSelectedFile] = useState();
    const [uploadProgress, setUploadProgress] = useState();

    const errorVisible = isInvalid && showErrors; // showErrors must be in the end because it's a timestamp

    useEffect(() => {
        selectedFile && upload();
    }, [selectedFile]);

    useEffect(() => {
        if (errorVisible && showErrors !== lastUsedShowErrors.value) {
            lastUsedShowErrors.value = showErrors;
            ref.current?.focus?.();
        }
    }, [errorVisible]);


    const upload = () => {
        let data = new FormData();
        data.append("file", selectedFile);

        setUploadProgress(0);
        axios.post(
            `${process.env.REACT_APP_API_URL}api/onboarding/file/upload_file`, data,
            {
                onUploadProgress: e => setUploadProgress(parseInt(100 * e.loaded / e.total)),
                headers: { 'Authorization': authorizationHeader }
            }).then(response => {
                if (response?.data?.setting.success == 1) {
                    const val = response?.data?.data;
                    delete val?.["$id"];
                    onChange?.(val);
                    setUploadProgress(0);
                } else {
                    alert(response?.data?.setting.message || 'File upload error!!');
                    cancel()
                }
            }, reason => {
                alert("Could not upload the file. Please try again.");
                console.error(reason);
                cancel();
            });
    }

    const cancel = () => {
        setUploadProgress(0);
        setSelectedFile(null);
        onChange?.(null);
    }

    const remove = () => {
        if (window.confirm("Are you sure to remove the uploaded file?")) {
            cancel();
        }
    }

    const getContent = () => {
        if (value) {
            return (
                <div>
                    <Link1 warning onClick={remove}>Remove</Link1>
                    <a className="govuk-link" href={value.Url} target="_blank" style={{ marginLeft: 16 }}>
                        {value.OriginalFilename}
                        {/* {selectedFile?.name} */}
                    </a>
                </div>
            );
        }

        if (uploadProgress) {
            return (
                <ProgressBar completed={uploadProgress} />
            );
        }

        return (
            <>
                <input
                    ref={ref} id={inputId} name={inputId}
                    className="govuk-file-upload" type="file" accept=".png,.jpg,.jpeg,.pdf"
                    onChange={e => setSelectedFile(e.target.files[0])}
                />
                {!!explanation && (
                    <Explanation>
                        {explanation}
                    </Explanation>
                )}
            </>
        );
    }

    return (
        <div className={"govuk-form-group" + (errorVisible ? " govuk-form-group--error" : "")}>
            {!!label && (
                <Label htmlFor={inputId} smallLabel={smallLabel}>
                    {label}
                </Label>
            )}
            {!!hint && (
                <Hint>
                    {hint}
                </Hint>
            )}
            {errorVisible && requiredMessage && (
                <p className="govuk-error-message">
                    <span className="govuk-visually-hidden">Error:</span> {uploadProgress ? "Please wait until the upload finishes" : requiredMessage}
                </p>
            )}
            {getContent()}
        </div>
    );
}
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "../../lib/components/govuk-frontend/Button";
import { Heading } from "../../lib/components/govuk-frontend/Heading";
import Link1 from "../../lib/components/govuk-frontend/Link1";
import OfficerPersonDetails from "./OfficerPersonDetails";
import OfficerAddress from "./OfficerAddress";
import OfficerAuth from "./OfficerAuth";
import OfficerRole from "./OfficerRole";
import OfficerType from "./OfficerType";
import { useEffect, useState } from "react";
import { getFirstInvalidFieldOfOfficer, officerName } from "./officer-helper-functions";
import Tabs from "../../lib/components/govuk-frontend/Tabs";

const tabs = [
    { label: "Type", Component: OfficerType },
    { label: "Role", Component: OfficerRole },
    { label: "Person", Component: OfficerPersonDetails },
    { label: "Auth", Component: OfficerAuth },
    { label: "Address", Component: OfficerAddress },
];

export default function NewCompanyOfficer({ data, setData, onComplete }) {

    const navigate = useNavigate();
    const { officerIndex, tabIndex } = useParams();
    const officer = data?.officers?.[officerIndex];

    const [searchParams] = useSearchParams();
    const singleFix = searchParams.has("singleFix");
    const paramShowErrors = searchParams.has("showErrors");

    const [showErrors, setShowErrors] = useState(paramShowErrors);

    useEffect(() => {
        changeShowErrors(paramShowErrors);
    }, [tabIndex, searchParams]);

    const changeShowErrors = x => {
        x = x ? new Date().getTime() : false;
        if (x != showErrors) {
            setShowErrors(x);
        }
    }

    const setActiveTabIndex = (i, showErrors) => {

        if (i >= tabs.length) {
            navigate("/officers");
            return;
        }

        let url = `/officer/${officerIndex}/${i}`;
        if (showErrors) {
            url += "?showErrors";
        }
        if (singleFix) {
            url += showErrors ? "&" : "?";
            url += "singleFix";
        }
        changeShowErrors(showErrors);
        navigate(url, { replace: true });
    }

    if (!officer || isNaN(tabIndex) || tabIndex >= tabs.length) {
        setTimeout(() => {
            navigate("/officers", { replace: true });
        }, 0);
        return;
    }

    const setOfficer = x => {
        setData(oldData => {
            const officers = [...oldData.officers];
            officers[officerIndex] = { ...officers[officerIndex], ...x };
            return { officers };
        });
    }

    const remove = () => {
        if (data.officers.length === 1) {
            alert("You can not remove the only officer!");
            return;
        }
        if (!window.confirm(`Are you sure to remove ${officerName(data, officerIndex)}?`)) {
            return;
        }
        const items = [...(data.officers || [])];
        items.splice(officerIndex, 1);
        setData({ officers: items });
        navigate(`/officers`, { replace: true });
    }

    const onContinue = () => {
        changeShowErrors(false);

        const invalidField = getFirstInvalidFieldOfOfficer(officer, singleFix ? null : tabIndex);

        if (invalidField) {
            setActiveTabIndex(invalidField.tabIndex, true);
            return;
        }
        if (singleFix) {
            navigate(-1);
            return;
        }
        setActiveTabIndex(parseInt(tabIndex) + 1);
        window.scrollTo(0, 0);
    }

    return (
        <>
            <Link to="/officers" className="govuk-back-link" id="back-to-officers-link">
                Back to officers
            </Link>
            {data.officers.length > 1 && (
                <Link1 warning onClick={remove} style={{ float: "right", fontSize: 14, marginTop: 15, lineHeight: 1.1428571429, display: "inline-block" }}>
                    Remove Officer
                </Link1>
            )}
            <Heading>
                {officerName(data, officerIndex)}
            </Heading>
            <Tabs
                headers={tabs.map((x, i) => `${i + 1}. ${x.label}`)}
                contents={tabs.map(({ Component }) => <Component officerName={officerName(data, officerIndex)} officer={officer} setOfficer={setOfficer} showErrors={showErrors} />)}
                activeIndex={parseInt(tabIndex)}
                setActiveIndex={setActiveTabIndex}
            />
            <div className="govuk-button-group" style={{ justifyContent: "space-between" }}>
                <Button secondary onClick={() => navigate("/officers")}>Back to officers</Button>
                <Button onClick={onContinue}>{singleFix ? "Done" : "Continue"}</Button>
                {data.officers.length > 1 && <Button warning onClick={remove}>Remove Officer</Button>}
            </div>
        </>
    );
}

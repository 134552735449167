import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { addressSummary } from "../lib/components/govuk-frontend/Address";
import { Button } from "../lib/components/govuk-frontend/Button";
import { dateToString } from "../lib/components/govuk-frontend/DateInput";
import Spinner from "../lib/components/govuk-frontend/Snipper";
import { plans } from "./ChoosePlan";
import { existingCompanySummary } from "./ExistingCompanyFind";
import { SnfAddress } from "./NewCompanyAddress";
import { getPersonName, getSharePercentage, getTotalNumberOfShares } from "./officer/officer-helper-functions";
import loadingIcon from "../assets/images/loading_icon.gif";
import { useAuthorizationHeader } from "../lib/helpers/auth";

export default function Summary({ data, setData }) {
    const authorizationHeader = useAuthorizationHeader();
    const [isSaving, setIsSaving] = useState();

    if (!data.allCompleted) {
        return <Navigate replace to="/" />;
    }

    const sections = [
        {
            header: "Company details",
            list: data.isNewCompany ? [
                {
                    key: "Company name",
                    value: data.companyName,
                    path: "/company-name"
                },
                {
                    key: "Planned business activities",
                    value: data.plannedBusinessActivities,
                    path: "/planned-business-activities"
                },
                {
                    key: "Registered office address",
                    value: data.useSnfAddress ? <SnfAddress /> : addressSummary(data.RegisteredOfficeAddress),
                    path: "/registered-office-address"
                },
                {
                    key: "Registered email address",
                    value: data.RegisteredEmailAddress,
                    path: "/registered-office-address"
                },
            ] : data.isExistingCompany ? [
                {
                    key: "Your Company",
                    value: existingCompanySummary(data.existingCompany),
                    path: "/find-your-company"
                },
                {
                    key: "Is VAT registered",
                    value: data.isExistingCompanyVatRegistered_Yes ? "Yes"
                        : data.isExistingCompanyVatRegistered_No ? "No"
                            : "",
                    path: "/vat-details"
                },
                data.isExistingCompanyVatRegistered_Yes && {
                    key: "VAT number",
                    value: data.existingCompanyVatNumber,
                    path: "/vat-details",
                    field: "vat-number"
                },
                data.isExistingCompanyVatRegistered_Yes && {
                    key: "VAT certificate",
                    value: (
                        <a className="govuk-link" href={data.existingCompanyVatCertificateDocument?.Url} target="_blank">
                            {data.existingCompanyVatCertificateDocument?.Filename}
                        </a>
                    ),
                    path: "/vat-details",
                    field: "vat-certificate"
                },
                // {
                //     key: "AUTH Code",
                //     value: (data.existingCompanyAuthCode || "").replace(/./g, '•'),
                //     path: "/auth-code"
                // },
            ] : [],
        },
        {
            header: "Your Contact details",
            list: [
                {
                    key: "Full name",
                    value: data.contactFullName,
                    path: "/contact-details",
                    field: "fullname"
                },
                {
                    key: "Email address",
                    value: data.contactEmail,
                    path: "/contact-details",
                    field: "email"
                },
                {
                    key: "Phone number",
                    value: data.contactPhone,
                    path: "/contact-details",
                    field: "tel"
                },
            ],
        },
    ];

    const totalNumberOfShares = getTotalNumberOfShares(data.officers);

    data.isNewCompany && (data.officers || []).forEach((o, i) => sections.push({
        header: data.officers?.length ? `Officer #${i + 1}` : "Officer details",
        list: [
            { key: "Type", value: o.isCorporate ? "Corporate" : o.isIndividual ? "Individual" : "", path: `/officer/${i}/0` },

            o.isCorporate &&
            { key: "Corparate name", value: o.corporateName, path: `/officer/${i}/0`, field: "corporate-name" },

            o.isCorporate &&
            { key: "Registration number", value: o.registrationNumber, path: `/officer/${i}/0`, field: "registration-number" },

            {
                key: "Roles",
                value: [
                    o.isPSC && "PSC",
                    o.isDirector && "Director",
                    o.isShareholder && ("Shareholder - " + getSharePercentage(o, totalNumberOfShares))
                ].filter(x => x),
                path: `/officer/${i}/1`
            },

            { key: "Person name", value: getPersonName(o), path: `/officer/${i}/2`, field: "title" },
            { key: "Date of birth", value: dateToString(o.dateOfBirth), path: `/officer/${i}/2`, field: "date-of-birth-day" },
            { key: "Nationality", value: o.nationality?.label, path: `/officer/${i}/2`, field: "nationality" },
            {
                key: "Proof of ID",
                value: (
                    <a className="govuk-link" href={o.proofOfIdDocument?.Url} target="_blank">
                        {o.proofOfIdDocument?.Filename}
                    </a>
                ),
                path: `/officer/${i}/2`,
                field: "proof-of-id"
            },
            { key: "Mother's maiden name", value: o.authMothersMaidenName, path: `/officer/${i}/3`, field: "auth-1" },
            { key: "Fathers's first name", value: o.authFathersFirstName, path: `/officer/${i}/3`, field: "auth-2" },
            { key: "Town of birth", value: o.authTownOfBirth, path: `/officer/${i}/3`, field: "auth-3" },
            { key: "Residential address", value: addressSummary(o.address), path: `/officer/${i}/4` },
            {
                key: "Proof of address",
                value: (
                    <>
                        <a className="govuk-link" href={o.proofOfAddressDocument?.Url} target="_blank">
                            {o.proofOfAddressDocument?.Filename}
                        </a>
                        {
                            o.proofOfAddressDocument2 && <>
                                <br/>
                                <a className="govuk-link" href={o.proofOfAddressDocument2?.Url} target="_blank">
                                    {o.proofOfAddressDocument2?.Filename}
                                </a>
                            </>
                        }
                    </>
                ),
                path: `/officer/${i}/4`,
                field: "proof-of-address"
            },



        ].filter(x => x)
    }));

    // TODO: Services
    /*
    sections.push({
        header: "Services",
        list: plans.map(x => (
            {
                key: x.label,
                value: data[`Packet${x.field}_Yes`] ? (<>Yes <code style={{ marginLeft: 16 }}>+£{x.price}</code></>)
                    : data[`Packet${x.field}_No`] ? "No"
                        : "",
                path: "/choose-packets",
                field: x.field
            }
        ))
    });
    */

    const handleClick = x => {
        setTimeout(() => {
            x.field
                ? window.location.replace(`#${x.field}`)
                : window.scrollTo(0, 0);
        }, 10);
    }

    const onAccept = () => {
        setIsSaving(true);

        const init = {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ ...data, isConfirmed: true }),
        }

        fetch(`${process.env.REACT_APP_API_URL}api/onboarding/company/session`, init).then(response => {
            if (response.status == 200) {
                setData({ isConfirmed: true });
            } else {
                saveError(response);
            }
        }, saveError);
    }

    const saveError = (reason) => {
        console.error(reason);
        window.alert("Could not submit your application. Please try again later.");
    }

    return (
        <div className="govuk-grid-column-two-thirds-from-desktop">
            <h1 className="govuk-heading-l">Check your answers before sending your application</h1>

            {sections.map((s, si) => (
                <div key={si}>
                    <h2 className="govuk-heading-m">{s.header}</h2>
                    <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                        {s.list.filter(x => x).map((x, xi) => (
                            <div key={xi} className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    {x.key}
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {
                                        Array.isArray(x.value)
                                            ? x.value.map((y, yi) => <p key={yi} className="govuk-body">{y}</p>)
                                            : x.value
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <Link
                                        className="govuk-link"
                                        to={!x.path ? "" : (x.path + "?singleFix")}
                                        onClick={() => handleClick(x)}
                                    >
                                        Change<span className="govuk-visually-hidden"> {x.key}</span>
                                    </Link>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            ))}


            <h2 className="govuk-heading-m">Now send your application</h2>
            <p className="govuk-body">By submitting this application you are confirming that, to the best of your knowledge, the details you are providing are correct.</p>

            <Button onClick={onAccept} disabled={isSaving}>
                {isSaving ? "Submitting your application..." : "Accept and send"}
                {isSaving && <img src={loadingIcon} width="16" style={{ marginLeft: 32 }} />}
            </Button>
        </div>
    );
}
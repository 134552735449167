import { RadioButtons } from "../lib/components/govuk-frontend/RadioButtons";
import { Details } from "../lib/components/govuk-frontend/Details";
import { Heading } from "../lib/components/govuk-frontend/Heading";
import { Input } from "../lib/components/govuk-frontend/Input";
import { Hint } from "../lib/components/govuk-frontend/Hint";
import { Label } from "../lib/components/govuk-frontend/Label";
import { useState } from "react";
import { ContinueButton } from "../lib/components/govuk-frontend/ContinueButton";
import { Navigate, useSearchParams } from "react-router-dom";
import FileUpload from "../lib/components/govuk-frontend/FileUpload";
import { Button } from "../lib/components/govuk-frontend/Button";
import { hmrcApi } from "../lib/helpers/hmrcApi";
import { useAuthorizationHeader } from "../lib/helpers/auth";
import { ExistingCompanyFind } from "./ExistingCompanyFind";

const vatNumberLength = 9;

ExistingCompanyVAT.hasStarted = data => {
    return data?.isExistingCompanyVatRegistered_No
        || data?.isExistingCompanyVatRegistered_Yes;
}

ExistingCompanyVAT.isValid = data => {
    return data?.isExistingCompanyVatRegistered_No
        || (data?.isExistingCompanyVatRegistered_Yes
            && data.existingCompanyVatNumber?.length === vatNumberLength);
}

export function ExistingCompanyVAT({ data, setData, onComplete }) {

    const authorizationHeader = useAuthorizationHeader();
    const [showErrors, setShowErrors] = useState(useSearchParams()[0].has("showErrors"));

    const validate = () => {
        if (ExistingCompanyVAT.isValid(data)) {
            return true;
        }
        setShowErrors(new Date().getTime());
        return false;
    }


    if (!ExistingCompanyFind.isValid(data)) {
        return <Navigate replace to="/find-your-company" />;
    }

    return (
        <>
            <Heading>VAT Details</Heading>
            <RadioButtons
                label="Is your company VAT Registered?"
                hint="VAT stands for Value Added Tax"
                options={["No", "Yes"]}
                checkedIndex={
                    data.isExistingCompanyVatRegistered_No ? 0
                        : data.isExistingCompanyVatRegistered_Yes ? 1
                            : null
                }
                onSelect={(_, i) => {
                    setData({
                        isExistingCompanyVatRegistered_No: i === 0,
                        isExistingCompanyVatRegistered_Yes: i === 1,
                    });
                }}
                requiredMessage="Please choose an option"
                showErrors={showErrors}
            />
            <Details
                summary="What is VAT?"
                body="VAT (Value Added Tax) is a tax added to most products and services sold by VAT-registered businesses. Businesses have to register for VAT if their VAT taxable turnover is more than £85,000. They can also choose to register if their turnover is less than £85,000." />

            {data.isExistingCompanyVatRegistered_Yes && (
                <>
                    <Input
                        pattern="[^0-9]" //type="number"
                        id="vat-number"
                        label="VAT Number"
                        hint={<></>}
                        autoComplete="vat-number"
                        inputClass="govuk-input--width-10"
                        prefix="GB"
                        value={data.existingCompanyVatNumber}
                        format={x => x.replace(/\D/g, '').replace(/^0+/, '').substring(0, vatNumberLength)}
                        onChange={x => setData({ existingCompanyVatNumber: x })}
                        requiredMessage="Please enter the VAT number"
                        isInvalid={data.existingCompanyVatNumber?.length !== vatNumberLength}
                        showErrors={showErrors}
                    />
                    {/* <Button
                        onClick={() => {
                            hmrcApi(authorizationHeader, "organisations/vat/check-vat-number/lookup/" + data.existingCompanyVatNumber)
                                .then(res => res.json().then(json => window.alert(json?.target?.name)))
                        }}
                    >
                        Check VAT number
                    </Button> */}
                    <Details
                        summary="What is VAT Number?"
                        body="VAT number stands for value added tax identification number. Having a VAT number means that a business will add a VAT price to the products or services that they sell. Businesses are then able to get back the VAT that they paid when buying their goods. " />
                    <FileUpload
                        id="vat-certificate"
                        label={"VAT Certificate"}
                        hint="Please upload your company's VAT certificate"
                        value={data.existingCompanyVatCertificateDocument}
                        onChange={x => setData({ existingCompanyVatCertificateDocument: x })}
                    />
                    <Details
                        summary="What is VAT Certificate?"
                        body="A VAT certificate is a document that HMRC provides to confirm that your business is officially registered for VAT. A VAT certificate includes: Your business's unique VAT number. The effective date of registration (the date from which VAT needs to be charged on VATable sales)"
                    />
                </>
            )}

            <ContinueButton data={data} validate={validate} onContinue={onComplete} />
        </>
    );
}

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "./Button";

export function ContinueButton({ data, onContinue, label, validate }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const singleFix = searchParams.has("singleFix");
    const allCompleted = data?.allCompleted;

    const handleClick = () => {
        if (typeof (validate) === "function") {
            if (!validate()) {
                return;
            }
        }

        if (singleFix) {
            navigate(-1);
            return;
        }

        if (allCompleted) {
            navigate("/summary");
            window.scrollTo(0, 0);
            return;
        }

        onContinue?.();
    }

    const getLabel = () => {
        return (
            allCompleted ? "Go to summary page"
                : singleFix ? "Done"
                    : (label || "Continue")
        );
    }

    return (
        <Button onClick={handleClick}>{getLabel()}</Button>
    );
}
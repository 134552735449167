import { Heading } from "../lib/components/govuk-frontend/Heading";
import { RadioButtons } from "../lib/components/govuk-frontend/RadioButtons";
import { Details } from "../lib/components/govuk-frontend/Details";
import { Button } from "../lib/components/govuk-frontend/Button";
import { useState } from "react";

LeadForm.hasStarted = data => {
    return data?.isNewCompany || data?.isExistingCompany;
}

LeadForm.isValid = data => {
    return data?.isNewCompany || data?.isExistingCompany;
}
LeadForm.path = "lead-form";
LeadForm.getTitle = (data) => data.isNewCompany ? "Setup New Company" : data.isExistingCompany ? "Switch to SnF" : "";

export function LeadForm({ data, setData, onComplete }) {

    const [showErrors, setShowErrors] = useState(false);

    const onContinue = () => {
        if (!LeadForm.isValid(data)) {
            setShowErrors(new Date().getTime());
            return;
        }
        onComplete?.();
    }

    return (
        <>
            <Heading style={{ fontSize: '36px' }}>Welcome to Sadler&Frost Accounting</Heading>
            <RadioButtons
                label="How do you want to work with us?"
                hint="We'll help you through your business in the UK"
                options={[
                    "I want to setup a new company",
                    "I already have a company, I just want to switch on to you",
                ]}
                checkedIndex={data.isNewCompany ? 0 : data.isExistingCompany ? 1 : null}
                onSelect={(_, i) => setData({ isNewCompany: i === 0, isExistingCompany: i === 1 })}
                requiredMessage="Please choose an option"
                showErrors={showErrors}
            />

            {/* <Details
                summary="What you'll need to complete the following steps"
                body={(
                    <ul className="govuk-list govuk-list--bullet govuk-list--spaced">
                        <li>Proof of ID (Passport, BRP, etc...)</li>
                        <li>Proof of Address (Utility bills, Driving licence, etc...)</li>
                    </ul>
                )} /> */}

            <Button onClick={onContinue}>Continue</Button>
        </>
    );
}
